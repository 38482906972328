import React from 'react'
import Content from '../Content'
import './politic.css'


export default props =>
    <Content>
        <div class="jumbotron jumbotron-fluid bg-politic">
            <div class="container">
                <h1 class="display-4"></h1>
                <p class="lead"></p>
            </div>
        </div>

    </Content>
