import React from 'react'
import Content from '../Content'
import './home.css'

import ladyvengance from '../../assets/img/ladyvengance.png'
import dancingDark from '../../assets/img/dance-in-the-dark-movie.jpg'
import oldboy from '../../assets/img/oldboy.jpg'


export default props =>
    <Content>

        <div class="jumbotron jumbotron-fluid bg-home">
            <div class="container">
            </div>
        </div>

        <div className="container my-5">
            <div className="row ">
                <div className="col-6"><h1></h1></div>
                <div className="col-6 d-flex justify-content-end align-items-center"><p className="none"></p></div>
                <div className="col-12 col-md-12">

                    <blockquote class="blockquote text-center">
                        <p class="mb-0 menor">                        “If I were to wish for anything, I should not wish for wealth and power, but for the passionate sense of the potential, for the eye which, ever young and ardent, sees the possible. <span className='bold-default'>Pleasure disappoints, possibility never.</span> And what wine is so sparkling, what so fragrant, what so intoxicating, as possibility!”</p>
                        <footer class="blockquote-footer menor">Søren Kierkegaard<cite title="Título da fonte">Either/Or: A Fragment of Life</cite></footer>
                    </blockquote>

                    {/* <p className="h6 my-4 text-center artigo">Aooba, me chamo Lucas, sou desenvolvedor de software, fã de evangelion e apaixonado por cinema.</p>
                    <p className="h6 my-4 text-center artigo">Aqui você encontrará gostos excêntricos, histórias tristes e opiniões impopulares.</p> */}
                    {/* <p className="h6 my-4 text-justify artigo">Não espere algo para você, em essência tudo que faço é para mim, se escrevo é para mim, se sofro é para mim, se amo é para mim, por agora quem quer que esteja lendo isso precisa saber, cometo erros ortográficos e semânticos rídiculos, mas não me importo não, isso aqui é um hobbie, não tenho tempo de revisar... Talvez se um dia eu escrever um livro me cobre sobre algo, mas agora sigo apenas me relembrando, tentar compreender porque cheguei onde cheguei, oque me afetou, e dar bastante risada no processo.</p> */}
                    {/* <p className="h6 my-4 text-justify artigo">Sou Analista de Desenvolvimento de Sistemas, atualmente trabalho com sistemas para industria de utilities, sou bolsista pelo prouni na PUC-Campinas, fiz ensino médio em várias escolas públicas de São Paulo e Hortolândia e tenho dois cursos técnicos pelo Centro Paula Souza, gosto de música, discussões com xingamentos, filmes, espiritualidade, questionamentos, feijoada e de falar em público. Sou Agnóstico desde os meus 12 anos, assumindo meu papel como satânicos na família católica, dai que veio meu codnome na internet "AGNOOS". Seguir assim me deu muita margem pra todo tipo de debate, não era questionado por não acreditar, ou por acreditar, agnóstico por definição se abstém de crença ou não crença, então assim, podia questionar um bocado de coisas. Isso me rendeu, ódio e admiração das pessoas, mas o fato é que eu sempre me achei chato, hoje olhando pra trás, cara, eu era insuportável, p@ta que o pariu...</p> */}
                    {/* <p className="h6 my-4 text-justify artigo">Talvez ja tenha dado para entender, eu saboreio um suculento arrependimento, mas por favor, não é só isso, não sou um depressivo de vida cinzenta, dou muitas risadas, e passo boa parte do meu tempo, divertindo e sendo divertido pelas pessoas a minha volta, adoro filmes do Shrek, pula pula do gugu, faço piadas com tudo, até comigo mesmo, falo besteira que não deveria só pra quebrar o gelo e acabo levando esporro, aquele que atravessa a rua correndo pq tem medo de ser atropelado, ja tropecei trocentas vezes na guia.</p> */}
                    {/* <p className="h6 my-4 mb-5 text-justify artigo">Acredito que tudo na vida vale a pena, sejam as dores ou as ecstasy da felicidade. Por isso aceito-me como sou, aceito as pessoas como elas são, e aceito o mundo como ele é, desde suas mais cruas selvajarias, as mais cultas luxurias. E aqui, em minha própria presença, vou escrever sobre oque eu quiser...</p>                 */}
                </div>
            </div>

            <hr className=' mb-5' />

            <h3 className="">Movie Style</h3>
            <hr />
            <div className="">
                <div className="row text-center mt-2">
                    <div className="col-md-3 col-6 text-center">
                        <h5 className="my-2">Fight Club</h5>
                        <div class="card test" >
                            <img class="card-img-top" src="https://a.ltrbxd.com/resized/film-poster/5/1/5/6/8/51568-fight-club-0-460-0-690-crop.jpg?v=768b32dfa4%202x" alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                        <h5 className="my-2">Before Sunrise</h5>
                        <div class="card test" >
                            <img class="card-img-top" src="https://a.ltrbxd.com/resized/film-poster/5/1/9/7/4/51974-before-sunrise-0-500-0-750-crop.jpg?v=de3c4718dc" alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                        <h5 className="my-2">Shame</h5>
                        <div class="card test" >
                            <img class="card-img-top" src="https://a.ltrbxd.com/resized/sm/upload/h1/2m/i0/od/wo6j31YThvs8VPTv9DAI3StSoby-0-460-0-690-crop.jpg?v=e096e7764d%202x" alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                        <h5 className="my-2">Another Round</h5>
                        <div class="card test" >
                            <img class="card-img-top" src="https://a.ltrbxd.com/resized/film-poster/5/0/8/2/4/6/508246-another-round-0-460-0-690-crop.jpg?v=6227862b11%202x" />
                        </div>
                    </div>
                </div>

                <div className="row text-center mt-2">
                    <div className="col-md-3 col-6 text-center">
                        <h5 className="my-2">Oldboy</h5>
                        <div class="card test" >
                            <img class="card-img-top" src={oldboy} alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                        <h5 className="my-2">Lady Vengeance</h5>
                        <div class="card test" >
                            <img class="card-img-top" src={ladyvengance} alt="Taxi Driver" />
                        </div>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                        <h5 className="my-2">Irreversible</h5>
                        <div class="card test" >
                            <img class="card-img-top" src="https://64.media.tumblr.com/008d414c43bc143fb936310a21b35a25/tumblr_oeniirpAII1r6ivyno1_640.jpg" alt="Interstellar" />
                        </div>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                        <h5 className="my-2">Handmaiden</h5>
                        <div class="card test" >
                            <img class="card-img-top" src="https://posterspy.com/wp-content/uploads/2019/01/Poster-2018-The-Handmaiden-Commercial.jpg" />
                        </div>
                    </div>
                </div>


                <h3 className="noneDesktop mt-5">...</h3>
                <hr className="noneDesktop" />
                <div className="row justify-content-center noneDesktop">
                    <div className="col-10 ">
                        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="d-block w-100" src="https://i.pinimg.com/originals/11/d4/2c/11d42cdad62fe14b4370dcb5e7af7181.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://upload.wikimedia.org/wikipedia/pt/0/0c/American_Psycho.png" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://media.fstatic.com/z16kuwSdWs3nR9_5tS6ZC_sXwwM=/290x478/smart/media/movies/covers/2013/10/a-professora-de-piano_t4283.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://images.justwatch.com/poster/245227645/s718" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://br.web.img2.acsta.net/r_1280_720/pictures/210/427/21042785_20130922063445757.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://eplay-prod.s3.eu-west-1.amazonaws.com/store/movie_region_poster/26308/file/movie_detail_image/16b1963eaf09d7602adecd506a3be109.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/M/MV5BODhkZGE0NDQtZDc0Zi00YmQ4LWJiNmUtYTY1OGM1ODRmNGVkXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/I/71G7AybM3qL._AC_SY879_.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/I/719k71fGvhL._AC_SL1500_.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://exlibris.azureedge.net/covers/4030/5217/4842/2/4030521748422xxl.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://img.elo7.com.br/product/zoom/265F027/big-poster-filme-la-la-land-lo01-tamanho-90x60-cm-poster-de-filme.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://i.pinimg.com/originals/33/72/6e/33726e086fd631da13160fa503381b81.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://bloximages.chicago2.vip.townnews.com/tucson.com/content/tncms/assets/v3/editorial/4/ff/4fface74-5cd0-11e4-b50f-47f5caa2a7a8/544c857948668.image.jpg?resize=500%2C780" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://tlkabtmovies.files.wordpress.com/2013/09/prisoners-poster.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/M/MV5BM2M1MmVhNDgtNmI0YS00ZDNmLTkyNjctNTJiYTQ2N2NmYzc2XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/I/51wILNNX2VL._AC_SY445_.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/I/81NuuGhiNmL._AC_SY741_.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://images.booksense.com/images/120/164/9798665164120.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://upload.wikimedia.org/wikipedia/pt/thumb/d/da/The_Irishman_p%C3%B4ster.png/235px-The_Irishman_p%C3%B4ster.png" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://br.web.img3.acsta.net/medias/nmedia/18/91/05/58/20127559.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://filmesclassicos.com/covers/000543.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://i.pinimg.com/originals/9f/fd/fb/9ffdfb519ad53eb7231f9353a1887362.png    " alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/I/A1-W2j6pPZL._AC_SY550_.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://br.web.img3.acsta.net/r_1280_720/pictures/18/12/07/12/40/4530128.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/I/510XMKV4TDL._AC_.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://images-na.ssl-images-amazon.com/images/I/91a10xGmuFL.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://www.kino-zeit.de/sites/default/files/news/bild19592.jpg" alt="" />
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://upload.wikimedia.org/wikipedia/pt/d/d2/The_Shawshank_Redemption_p%C3%B4ster.png" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://upload.wikimedia.org/wikipedia/pt/e/e1/Reservoir_Dogs_1992.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/M/MV5BNDM2MzMwMzcxNF5BMl5BanBnXkFtZTcwNTczMDk3OA@@._V1_.jpg" alt="" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="https://m.media-amazon.com/images/M/MV5BOTNjM2Y2ZjgtMDc5NS00MDQ1LTgyNGYtYzYwMTAyNWQwYTMyXkEyXkFqcGdeQXVyMjE4NzUxNDA@._V1_.jpg" alt="" />
                                </div>

                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>







                {/* <h3 className="mt-5">Assistindo agora</h3>
            <hr/>
                <div className="row text-center">
                <div className="col-3 none"></div>
                    <div className="col-6 col-md-3">
                    <h5 className="my-2">Dancer in the Dark (2000)</h5>
                    <div class="card test" >
                        <img class="card-img-top" src={dancingDark} alt="Uma Imagem" />
                    </div>
                    </div>
                    <div className="col-6 col-md-3">
                    <h5 className="my-2">Perfect Blue (1997)</h5>
                    <div class="card test" >
                        <img class="card-img-top" src="https://www.magazine-hd.com/apps/wp/wp-content/uploads/2019/03/monstra-perfect-blue-poster.jpg" alt="Uma Imagem" />
                    </div>
                    </div>
                    <div className="col-3 none"></div>

                </div> */}


                <h3 className="mt-5">Albuns</h3>
                <hr />

                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-12">
                            <div className="row text-center mt-2 mb-2">
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2735a721e58cfabeb2b22839e59" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ed3953f8af1f764a146b7583" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e023932e4fc8c9e69f72e6ec994" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e0223444c767d67417474f103e3" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e020a7d45a345534966a4ad2c39" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273f65d720a5d70dbb93e96d528" alt="Uma Imagem" />
                                    </div>
                                </div>
                            </div>
                            <div className="row text-center mt-2 mb-2">
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e0229eb9ad9b9af34bbb54eb053" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273c428f67b4a9b7e1114dfc117" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273e4983a286a861bd8ff67bc53" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273863e0e305637100311c91aa7" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e022ba83d30de29062f9bb9ccef" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e023ba1f72fb3bf152db4547435" alt="Uma Imagem" />
                                    </div>
                                </div>
                            </div>
                            <div className="row text-center mt-2 mb-2">
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ceb24a1906254750e82c1db7" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02003f81b1f4246362638e682d" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ada101c2e9e97feb8fae37a9" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ca5dd35539f06aaf585ef769" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e025c9890c0456a3719eeecd8aa" alt="Uma Imagem" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-4 mt-2 text-center">
                                    <div class="card test" >
                                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ebc8cfac8b586bc475b04918" alt="Uma Imagem" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="col-12 text-center mt-3">
                                <h4 className="text-dark mb-1">Tocando agora</h4>
                                <a href="https://spotify-github-profile.vercel.app/api/view?uid=niibiru&redirect=true" target="_blank"><img className="img-fluid" src="https://spotify-github-profile.vercel.app/api/view.svg?uid=niibiru&cover_image=true&theme=default&bar_color=ede8ee&bar_color_cover=false" alt="" /></a>
                                    {/* <div className="text-center">
                                        <iframe className="" src="https://open.spotify.com/embed/track/4EKMLcDmI7I5bORpsWgQg1" width="215" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>


        <hr />
    </Content>
