import React from 'react'
import Content from '../Content'
import './fightclub.css'
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import img1 from '../../assets/img/evangelion1.jpg'
import produtora from '../../assets/img/evangelion-filmadora.png'
import diretor from '../../assets/img/evangelion-diretor.jpg'


export default props =>
    <Content>

        {/* <div class="jumbotron jumbotron-fluid bg-euphoria">
            <div class="container">
                <h1 class="display-4 text-light">Euphoria</h1>
                <p class="lead text-light">A essência rasa da realidade inventada dos jovens 2020, drogas, sexualidade e abusos. Aula da HBO de como ganhar dinheiro...</p>
            </div>
        </div> */}

        <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="d-block w-100" src="https://livrismos.files.wordpress.com/2013/12/clube-luta-e1387276271653.jpg?w=1200&h=400&crop=1" alt="First slide" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row mt-2">



                <div className=" none col-md-6 col-12 d-flex justify-content-start align-items-start mt-md-5 flex-column">
                    <p className="none"><span className="font-weight-bold mr-5">Formato:</span>Filme</p>
                    <p className="none"><span className="font-weight-bold mr-5">Gênero:</span>Realidade</p>
                    <p className="none font-italic"><span className="font-weight-bold mr-5">Roteiro Por:</span>Chuck Palahniuk</p>
                    <p className="none"><span className="font-weight-bold mr-5">Pais:</span>Estados Unidos</p>

                </div>

                <div className="col-md-3 col-6 mb-4">
                    <p className="lead text-center legend">Diretor</p>
                    <p className="lead text-center text-primary legend">David Fincher</p>

                    <div class="card test" >
                        <img class="card-img-top" src="https://cdn.vox-cdn.com/thumbor/lTjQY97kU8Rq0Rr2bKRnSr5PXJE=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22031337/1201364271.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-3 col-6 mb-4">
                    <p className="lead text-center legend">Produtora</p>
                    <p className="lead text-center text-primary legend">Century FOX</p>

                    <div class="card test" >
                        <img class="card-img-top" src="https://exame.com/wp-content/uploads/2020/01/disney-fox.jpg?quality" alt="Uma Imagem" />
                    </div>
                </div>

            </div>

            <hr />

            {/* <div className="row">
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://lh3.googleusercontent.com/proxy/MRdIx0AFj7P1bckOzVeUsuIOS-ZxJeTHcST76i1xzzEsRKvM1JmKSBZum5UtC5CBIDhlZTtSVDcvMXnY7Xqrl2M3Dtju5vsX07RTTfu7u8gWgom8FXNaj_TqJI7lcRvp94aPtFUEsic-cwFCB_OCXlLet978Jgm1G9oH" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://dotandline.net/wp-content/uploads/2019/06/Shinjiprofil-1024x642.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://i.pinimg.com/originals/f4/21/55/f421556eb56996e04295fc07133d0f72.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://upload.wikimedia.org/wikipedia/en/2/2f/Asukka13.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/a/a2/OP_C015_ritsuko.jpg/260px-OP_C015_ritsuko.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/9/9b/08_C071_kaji.jpg/260px-08_C071_kaji.jpg" alt="Uma Imagem" />
                    </div>
                </div>
            </div> */}

    <div className="row mt-4 mb-5">
         <div className="col-12">
            <h2 className="display-3 text-center">Clube da Luta</h2>
         </div>
         <div className="col-12 col-md-12 mt-2">
            <p className="artigo text-justify lead">
            Esse filme é bem famoso, David Fincher é pioneiro em personagens, enredos de protagonistas e histórias que giram em torno de um ponto focal. Clube da Luta talvez seja o seu mais conhecido, mas a rede social e garota exemplar, cruzam o estilo deste diretor, em prender a atenção do espectador, que mesmo acompanhando todos os personagens, tem dúvidas sobre quem está certo, quem é o vilão. As tramas são gostosas e agradáveis, não existem reviravoltas tão inesperadas, porém, a construção gradual da possibilidade de plot twist, o torna bom a mesmo nível.            </p>
            <p className="artigo text-justify lead mt-1">
            Clube da luta retrata problemas psicológicos que afetam a vida do protagonista, ele sofre de insônia, ela destrói sua produtividade, seu interesse pelas coisas, seu foco e sua motivação, tudo que ele pode fazer é se esforçar para ocupar sua mente em grupos de apoio, dos mais variados tipos, buscando ao menos por um instante, estar em algo, dividir todos estes problemas com alguém.            </p>
            <div className="col-12 my-4 text-center">
               <img class="card-img-top" src="https://miro.medium.com/max/1000/1*WnNMba4Q9Z0manf6Rj6j-Q.gif" alt="Uma Imagem" />
            </div>

            <div className="row">
                <p className="artigo text-justify lead col-md-6 col-12">
                A insônia, diminui mais ainda, as coisas que um homem pode fazer para encontrar propósito em sua vida, acredito que isso seja o ponto principal de identificação neste filme, desde a revolução industrial e a ascensão da manufatura, o viés operacional, de produção massiva, muitas vezes de produtos que não representam o funcionário, fez com que as pessoas não encontrassem mais motivos prazerosos para se trabalhar, não existia propósito, sua vocação, sonhos e talentos são deixados de lado, tudo por cédulas de papel, que numa convenção mundial, acreditamos possuir algum valor, para comprar produtos que nos darão motivos ? Prazeres ? vontade de viver ?
                </p>

                <div className="col-12 col-md-6 mt-2 text-center">
                    <img class="card-img-top" src="https://pa1.narvii.com/6356/d8f1314cbfd444d78115bf8cb8ef2c47d95a9432_hq.gif" alt="Uma Imagem" />
                </div>
            </div>

            <div className="row mt-4">
                <p className="artigo text-justify lead col-12">
                Clube da luta é o clube da luta, estou indo contra a primeira e a segunda regra falando sobre ele, mas já que estamos aqui, vamos assumir esse propósito, e esse propósito foi criado para ser um propósito, um motivo, uma posição, uma existência. O filme explora muito bem como buscamos fazer parte de algo, de um grupo, de uma nação, como nos sentimos úteis, amados, e seguimos nossa característica natural, de defender nossos semelhantes, assumir suas dores, sonhos e ódio.
                </p>
            </div>

            <div className="col-12 col-md-12 d-flex align-items-center">
                        <blockquote class="blockquote my-5">
                            <p class="mb-0 text-center">"Por que será que vivemos trabalhando para produzir o que não consumimos e, em troca disso, consumimos o que não nos é útil e temos o que não utilizamos, e, por fim, nunca estamos satisfeitos?"</p>
                            <footer class="blockquote-footer text-center">Tyler Durdan<cite className="ml-2" title="Título da fonte">Fight Club</cite></footer>
                        </blockquote>
            </div>

            <div className="col12 col-md-12 mt-2">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe width="1912" height="778" src="https://www.youtube.com/embed/Ck0CpyJUK8A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
            </div>

            <div className="row mt-5 ">
                <p className="artigo text-justify lead col-12">
                Depois de tantos grupos de apoio frequentados, o protagonista acaba tendo a ideia de criar o seu próprio, daí que surge a ideia do Clube da Luta, um lugar onde homens são aceitos, independente de sua classe social, de seu emprego, de seus pecados ou crimes, é uma anarquia, contra governo, contra a sociedade capitalista, contra o mercado de marketing que segundo eles, acabam com seus reais propósitos, com sua vontade de viver.                </p>
            </div>
            
            <div className="row mt-2">
                <p className="artigo text-justify lead col-12">
                    
                O filme tem cenas maravilhosas, lutas viscerais, auto mutilação, relacionamentos abusivos e tudo que a cabeça de um homem frustrado e problemático pode criar, é aqui que entra nosso plot.
                </p>
            </div>

            <div className="col12 col-md-12 mt-2">
                    <div class="embed-responsive embed-responsive-16by9">

                    <iframe width="1912" height="778" src="https://www.youtube.com/embed/cRGtnDTQoyY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </div>

            <div className="row mt-5">
                <p className="artigo text-center lead col-12">

                O protagonista possui duas versões, mas ele não sabe disso, de alguma forma seu inconsciente o leva às suas pretensões mais agudas. Anarquismo, violência, coragem e determinação. De alguma forma todos os desejos profundos, os anseios sinceros e inconsequentes, são aflorados durante a noite, e com ele, o plano para encontrar a reivindicação da existência é iniciada, a saída do anonimato, da rotina capitalista e material, a aceitação de si próprio, o orgulho de ser quem é. Quando ninguém mais pode te ajudar, só você pode se apoiar, nada pode te desanimar, nada pode te diminuir, você não precisa de ninguém, só de si mesmo. Bem vindo ao Clube da Luta.
                </p>
            </div>

            <hr/>

            <div className="row text-center">
                <div className="col-md-6 col-12 mt-5">
                <h4 className="">Trilha da Série</h4>
                <iframe src="https://open.spotify.com/embed/album/7BNrUfFSDHEbj1hi12yjdj" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>

                <div className="col-md-6 col-12 mt-5">
                <h4 className="">Minha trilha favorita da Série</h4>
                <iframe src="https://open.spotify.com/embed/track/7wCmS9TTVUcIhRalDYFgPy" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

</Content>
