import React from 'react'
import Content from '../Content'
import './games.css'

import ladyvengance from '../../assets/img/ladyvengance.png'
import dancingDark from '../../assets/img/dance-in-the-dark-movie.jpg'
import oldboy from '../../assets/img/oldboy.jpg'

import dima from '../../assets/img/dima.png'
import setup from '../../assets/img/setup.png'
import cs from '../../assets/img/cs.png'
import hearthstone from '../../assets/img/hearhts.png'
import over from '../../assets/img/over.png'

export default props =>
    <Content>

        <div class="jumbotron jumbotron-fluid bg-home">
            <div class="container">
            </div>
        </div>

        <div className="container">

            <hr />

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <a href="https://steamcommunity.com/id/Agnoos" target="_blank">
                            <div className="text-center">
                                <img src="https://logodownload.org/wp-content/uploads/2018/01/steam-logo.png" alt="" height="60" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <hr />
            <div className="row setup ml-1 mr-1">
                <div className="col-12 col-md-5 mx-1 mt-md-4 mt-2 pequeno">
                    <h2 className=" text-center text-info text-md-left">Setup</h2>
                    <p className="nick setup2"><span className="nick setup3 text-info">Placa Mãe : </span> GIGABYTE B450 AORUS ELITE</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Placa de Vídeo : </span> Zotac NVIDIA RTX 3070</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Processador : </span> AMD Ryzen™ 5 5600X</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">SSD : </span> Kingston NV1 250GB, M.2 2280 NVMe</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">SSD 2 : </span> SSD Kingston A400, 480GB, SATA</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">HD : </span> 2X HD Seagate Skyhawk, 2 TB</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Monitor Primário : </span> BenQ ZOWIE 144hz 1ms</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Monitor Secundário : </span> AOC 144Hz 1ms</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Mouse : </span> Logitech G403 HERO</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Teclado : </span> HyperX FPS Pro</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Headset : </span> HyperX Cloud II</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Webcam : </span> Logitech C920s</p>
                    <p className="nick setup2"><span className="nick setup3 text-info">Utilitário 2 : </span> 2x Suporte Articulado F80N ELG</p>
                </div>

                <div className="col-md-6 col-12 text-center my-5">
                    <div class="card test" >
                        <img class="card-img-top" src={setup} alt="Uma Imagem" />
                    </div>
                </div>
            </div>
            <h3 className=" mt-5 text-center text-md-left">Counter Strike</h3>

            <div className="row text-center mt-2">
                <div className="col-md-8 col-12 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src={cs} alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-md-0 mt-3 text-justify">
                    <p className="nick"><span className="nick text-dark">Nick: </span> BIFÃO ACEBOLADO</p>
                    <p className="nick"><span className="nick text-dark">Role: </span> Awper</p>
                    <p className="nick"><span className="nick text-dark">Patente: </span> Global Elite</p>
                    <p className="nick text-success"><span className="nick text-dark">Status Ranked: </span>Ativo</p>
                    <p className="nick text-success"><span className="nick text-dark">Highlights: </span><a target="#" href="https://www.youtube.com/channel/UCukWSC_GYf4dYAXeerHCc8g">Canal</a></p>
                    <p className="nick text-danger"><span className="nick text-dark">Status GC: </span>Aposentado</p>
                    <p className="nick text-warning"><span className="nick text-dark">Inventory Value: </span>R$ 25.000.00</p>
                    <a className="lucas nick" href="https://steamcommunity.com/id/Agnoos" target="#">Steam</a>
                </div>
            </div>

            <hr />

            <h3 className=" mt-5 text-center text-md-left">Overwatch</h3>

            <div className="row text-center mt-2">
                <div className="col-md-8 col-12 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src={over} alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-md-0 mt-3 text-justify">

                    <p className="nick"><span className="nick text-dark">Nick:</span> BIFE</p>
                    <p className="nick"><span className="nick text-dark">Blizzard Tag: </span> #11188</p>
                    <p className="nick"><span className="nick text-dark">Main: </span> Tracer</p>
                    <p className="nick"><span className="nick text-dark">Patente: </span>Grão Mestre DPS - Mestre Sup</p>
                    <p className="nick text-success"><span className="nick text-dark">Status Ranked: </span>Ativo</p>
                </div>
            </div>

            <h3 className=" mt-5 text-center text-md-left">Hearthstone</h3>

            <div className="row text-center mt-2">
                <div className="col-md-8 col-12 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src={hearthstone} alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-md-0 mt-3 text-justify">

                    <p className="nick"><span className="nick text-dark">Nick:</span> BIFE</p>
                    <p className="nick"><span className="nick text-dark">Blizzard Tag: </span> #11188</p>
                    <p className="nick"><span className="nick text-dark">Elo: </span> Lenda</p>
                    <p className="nick text-danger"><span className="nick text-dark">Status Ranked: </span> Ativo</p>
                </div>
            </div>

            <hr />

            <h3 className=" mt-5 text-center text-md-left">Apex Legends</h3>

            <div className="row text-center mt-2">
                <div className="col-md-8 col-12 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://media.contentapi.ea.com/content/dam/apex-legends/common/articles/war-games/apex-legends-screenshot-season8-wargames-03-ultra-zones-clean.jpg.adapt.crop191x100.628p.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-md-0 mt-3 text-justify">

                    <p className="nick"><span className="nick text-dark">Nick:</span> BIFÃO ACEBOLADO</p>
                    <p className="nick"><span className="nick text-dark">Main: </span> Octane</p>
                    <p className="nick"><span className="nick text-dark">Patente: </span> Mestre 2020</p>
                    <p className="nick text-success"><span className="nick text-dark">Status Ranked: </span>Inativo</p>
                </div>
            </div>


            <hr />

            <h3 className=" mt-5 text-center text-md-left">League Of Legends</h3>

            <div className="row text-center mt-2">
                <div className="col-md-8 col-12 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://steamuserimages-a.akamaihd.net/ugc/957483070093055113/DABA77BF74CE845A1F1DA8A3A1ABA5FA54479C9A/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-md-0 mt-3 text-justify">
                    <p className="nick"><span className="nick text-dark">Nick: </span> BIFÃO ACEBOLADO</p>
                    <p className="nick"><span className="nick text-dark">Role: </span> TOP</p>
                    <p className="nick"><span className="nick text-dark">Elos: </span> </p>
                    <span className="dima">Diamante 2017</span><span className="dima">Diamante 2018</span><span className="dima">Diamante 2019</span><span className="dima">Diamante 2020</span><span className="dima">Diamante 2021</span>
                    <p className="nick text-danger"><span className="nick text-dark">Status Ranked: </span>Aposentado</p>
                    <p className="nick text-success"><span className="nick text-dark">Highlights: </span>TOP 1 Illaoi BR - 2019/2020</p>
                    <p className="nick text-success"><span className="nick text-dark">Status TFT: </span>Ativo</p>
                    <a className="lucas nick" href="https://www.leagueofgraphs.com/pt/summoner/br/BIF%C3%83O%20ACEBOLADO" target="#">League Of Graphs</a>
                </div>
            </div>

            <hr />

        </div>

    </Content>
