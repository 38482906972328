import React from 'react'
import Content from '../Content'
import './me.css'



export default props =>
    <Content>

        <div class="jumbotron jumbotron-fluid bg-history">

        </div>

        <div className="container my-5"></div>


{/* Section */}

        <div class="container mt-5">

            <div className="row">
                <div className="col-6">
                    <h5 className="text-left menor">Concorde - Just Kiss Her</h5>
                </div>
                <div className="col-6">
                    <h5 className="text-right menor">27/03/2022</h5>
                </div>
            </div>

            <hr />

            <p className="menor text-justify">Hoje passei um tempo pensando sobre mim, não entrei muito no discord com a rapaziada, então tive um bom tempo sozinho. Acho que me sinto um pouco isolado das coisas, me refiro às relações sociais, já faz algumas semanas que me pego com sintomas de carência, nunca tive isso, nem mesmo nesses últimos anos em pandemia, mas agora tá começando a aparecer e estou aprendendo a lidar. Acho que buscar o perfil de pessoas antigas, principalmente garotas que conheci, não me ajuda em nada, na verdade me deixa com mais medo de tomar atitude para alguma coisa. Mas eu não preciso de nada, é isso que sempre acreditei, eu não preciso de ninguém além de mim mesmo, quero continuar pensando assim ao mesmo tempo não quero também. </p>

        </div>

{/* Fim da Section */}

{/* Section */}

<div class="container mt-5">

<div className="row">
    <div className="col-6">
        <h5 className="text-left menor">Muse - Knight of Cydonia</h5>
    </div>
    <div className="col-6">
        <h5 className="text-right menor">21/03/2022</h5>
    </div>
</div>

<hr />

<p className="menor text-justify">Todos os dias fico feliz com os resultados que alcancei, hoje, uma segunda feira, trabalhei demais, adiantei as coisas que precisava adiantar no trabalho, joguei meus jogos com meus amigos pela tarde no discord, fiz alguns exercícios ao anoitecer e antes de dormir me peguei planejando os detalhes do apartamento, espero que todo o esforço e todos os sacrifícios tragam os resultados. Seria muito frustrante, depois de toda essa luta, morrer sem ter aproveitado nada disso, sempre penso nisso kkkkk, mas se eu estiver morto não vou sentir nada nao, o problema é ficar paralisado, vivo, ai ta de sacanagem, mas não vai acontecer não, vou beber bastante água. </p>

</div>

{/* Fim da Section */}

<div class="container mt-5">

<div className="row">
    <div className="col-6">
        <h5 className="text-left menor">The Growlers - Monotonia</h5>
    </div>
    <div className="col-6">
        <h5 className="text-right menor">18/03/2022</h5>
    </div>
</div>

<hr />

<p className="menor text-justify">Sexta foi bastante corrido e semana que vem terei muito trabalho a fazer, participei de alguns drops com a rapaziada do discord, foi bacana, conseguimos tirar uma grana boa, teria sido um dia bom, se nao fossem as brigas da minha mãe com a minha irmã, as duas não se entendem nunca, infelizmente, todo o convívio que tive com as duas me fez querer ir embora, amo muito elas, sempre serão minha família, mas a muito tempo me decidi de me separar, não apenas no quesito ter comprado meu apartamento, mas no quesito ambição, futuro e ciclo de pessoas.</p>

</div>

{/* Fim da Section */}

<div class="container mt-5">

<div className="row">
    <div className="col-6">
        <h5 className="text-left menor">STRFKR - Golden Light</h5>
    </div>
    <div className="col-6">
        <h5 className="text-right menor">17/03/2022</h5>
    </div>
</div>

<hr />

<p className="menor text-justify">Hoje foi meu aniversário, depois de tanto tempo sem isso estar explícito para maioria das pessoas nas redes sociais, recebi felicitações apenas da minha família e alguns amigos próximos, todos no trabalho cantaram parabéns para mim, acho que chegou no email de geral alguma notificação, agora como uma empresa que faz parte do grupo nacional, deve ter essas frescurinhas. O tempo passa voando, jaja faço trinta, depois quarenta, mas hoje não foi dia de pensar nisso.</p>

</div>

{/* Fim da Section */}

<div class="container mt-5">

<div className="row">
    <div className="col-6">
        <h5 className="text-left menor">Arctic Monkeys - My Propeller</h5>
    </div>
    <div className="col-6">
        <h5 className="text-right menor">08/03/2022</h5>
    </div>
</div>

<hr />

<p className="menor text-justify">Entreguei 4 tasks hoje e já adiantei as próximas, então peguei esta terça feira para fazer algumas coisas aqui em casa, montei uma maquina que comprei, foi insano de difícil montar sozinho, precisava de DUAS chaves inglesas com abertura de 30 milímetros, uma para girar o parafuso e outra para segurar a porca M16 com acabamento de silicone, precisei comprar no mercado livre com entrega full, assim que chegou já mandei bala. Estou com a mão muito dolorida, mas a máquina tá de pé.</p>

</div>

{/* Fim da Section */}

<div class="container mt-5">

<div className="row">
    <div className="col-6">
        <h5 className="text-left menor">Titãns - Epitáfio</h5>
    </div>
    <div className="col-6">
        <h5 className="text-right menor">23/02/2022</h5>
    </div>
</div>

<hr />

<p className="menor text-justify">Hoje foi um dia pensativo, quarta feira é o único dia que não tenho toneladas de reuniões no serviço então o desenvolvimento é muito mais produtivo, nesses momentos me peguei pensando, e de certa forma até me sentindo superior a outras pessoas sobre o seguinte questionamento, como pode, perante todas as minhas dificuldades, eu ter de engolir tantas desculpas das pessoas, que dizem não conseguir fazer, não serem suficientes, não terem tido sorte. Eu trabalho, ajudo em tudo que posso em casa, filho de mãe solteira, aprendendo o'que tenho o dever de aprender, e preciso ter empatia com alguém que não consegue trocar um chuveiro? desentupir um encanamento? Mas na real, tive um insight, não sou superior por nada que sou, eu posso ser bom em tantas coisas consideradas maduras, posso ter sido um exemplo parental terminando minha faculdade, comprando minha casa, sendo um apoio financeiro para família, mas oque isso me custou ? As vezes me arrependo de tudo, as vezes sinto que paguei um alto preço por todo tempo que levei para construir esse caráter, toda vez que desabafo com as pessoas sobre isso, elas me dizem que ainda sou muito novo, e posso aproveitar o tempo a partir daqui, mas eu nao quero mais, o problema não é mais o tempo, é a concepção, eu perdi o tesão, tenho medo de ter virado um boomer, a maioria das coisas que vejo por aí me dão vergonha ou repulsa, eu gostaria de não sentir isso, eu gostaria de ter sido mais negligente, mais inconsequente, eu gostaria de ter histórias pra contar. </p>

</div>

{/* Fim da Section */}

{/* Fim da Section */}

<div class="container mt-5">

<div className="row">
    <div className="col-6">
        <h5 className="text-left menor">Sharon Van Etten - Jupiter 4</h5>
    </div>
    <div className="col-6">
        <h5 className="text-right menor">01/02/2022</h5>
    </div>
</div>

<hr />

<p className="menor text-justify">Ando entrando muito no perfil de uma garota idealizada, da qual não conheço, refletindo meus desejos com criatividade sobre ela, uma utópica fantasia romântica que serve de combustível para sustentar o meu estilo de vida atual, distante e desconectado. Essa ideia parece assustadora, uma pessoa pensando em você sem nem mesmo saber quem você é, mas na minha própria cabeça, tenho a liberdade para imaginar exatamente como eu gostaria que fosse, sou apaixonado por tudo que imagino, e isso independe das pessoas, sou apaixonado pelos meus próprios sonhos, talvez isso me frustre. </p>

</div>

{/* Fim da Section */}


{/* Fim da Section */}

<div class="container mt-5">

<div className="row">
    <div className="col-6">
        <h5 className="text-left menor">Trent Reznor - In Motion</h5>
    </div>
    <div className="col-6">
        <h5 className="text-right menor">24/01/2022</h5>
    </div>
</div>

<hr />

<p className="menor text-justify">Hoje mantive os cronogramas para meus investimentos em NFT, engraçado pensar nisso porque até algum tempo atrás achava isso uma idiotice, hoje estou buscando o procedimento para abertura de uma DEM para receita federal, para que eu não caia na malha fina, de qualquer forma, também vou me preparar para o bear market, o inverno que vem aí com as cryptos. A tensão geopolítica na Rússia me deu um gás pra fazer uma entrada short no bitcoin, resultado, perdi 600 dólares hoje, não vou mais mexer com isso não, de qualquer forma, vender NFT para idiotas fechou minhas contas por muitos meses, só tenho a agradecer. </p>

</div>

{/* Fim da Section */}



{/* Fim da Section */}

<div class="container mt-5 mb-5">

<div className="row">
    <div className="col-6">
        <h5 className="text-left menor">Fear of Men - Sane</h5>
    </div>
    <div className="col-6">
        <h5 className="text-right menor">04/01/2022</h5>
    </div>
</div>

<hr />

<p className="menor text-justify">Me sinto deslocado das redes sociais, eu não sei mexer muito bem nelas, lembro até da época do Orkut, eu fui mandar mensagem privada para uma pessoa na época e postei para todo mundo, aquele dia na quarta série do José Romeu da Silva foi complicado, todo mundo sabia o que eu tinha feito, menos eu, achando que tava guardando segredo. Nunca tive instagram, eu não tiro fotos, não sei o que faria lá, no facebook não atualizo educação, trabalho e nem foto de perfil a uns 5 anos, depois de um tempo eu já não sabia como me expressar nesses lugares. Um exemplo é o twitter, eu nao sei dizer as coisas que gostaria lá, sempre tenho a impressão que estaria querendo que as pessoas soubessem o que estou fazendo, não porque necessariamente querem me conhecer ou participar do meu dia-a dia, e sim por simples liquidez, saber oque os outros estão fazendo para comparar com as nossas próprias vidas, para se incluir numa perspectiva específica. Lendo isso, pareço um indivíduo que faz tempestade em copo d'água, coisas tão simples que eu complico, e eu concordo, mas estou tentando me soltar mais, aprender a me expressar, aprender a dizer as coisas certas nas horas certas e as vezes só não me importar mesmo.</p>

</div>

{/* Fim da Section */}






</Content>
