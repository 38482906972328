import React from 'react'
import Content from '../Content'
import './junie.css'
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import img1 from '../../assets/img/evangelion1.jpg'
import produtora from '../../assets/img/evangelion-filmadora.png'
import diretor from '../../assets/img/evangelion-diretor.jpg'


export default props =>
    <Content>

        {/* <div class="jumbotron jumbotron-fluid bg-euphoria">
            <div class="container">
                <h1 class="display-4 text-light">Euphoria</h1>
                <p class="lead text-light">A essência rasa da realidade inventada dos jovens 2020, drogas, sexualidade e abusos. Aula da HBO de como ganhar dinheiro...</p>
            </div>
        </div> */}

        <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="d-block w-100" src="https://livrismos.files.wordpress.com/2013/12/clube-luta-e1387276271653.jpg?w=1200&h=400&crop=1" alt="First slide" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row mt-2">



                <div className=" none col-md-6 col-12 d-flex justify-content-start align-items-start mt-md-5 flex-column">
                    <p className="none"><span className="font-weight-bold mr-5">Formato:</span>Filme</p>
                    <p className="none"><span className="font-weight-bold mr-5">Gênero:</span>Realidade</p>
                    <p className="none font-italic"><span className="font-weight-bold mr-5">Roteiro Por:</span>Chuck Palahniuk</p>
                    <p className="none"><span className="font-weight-bold mr-5">Pais:</span>Estados Unidos</p>

                </div>

                <div className="col-md-3 col-6 mb-4">
                    <p className="lead text-center legend">Diretor</p>
                    <p className="lead text-center text-primary legend">David Fincher</p>

                    <div class="card test" >
                        <img class="card-img-top" src="https://cdn.vox-cdn.com/thumbor/lTjQY97kU8Rq0Rr2bKRnSr5PXJE=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22031337/1201364271.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-3 col-6 mb-4">
                    <p className="lead text-center legend">Produtora</p>
                    <p className="lead text-center text-primary legend">Century FOX</p>

                    <div class="card test" >
                        <img class="card-img-top" src="https://exame.com/wp-content/uploads/2020/01/disney-fox.jpg?quality" alt="Uma Imagem" />
                    </div>
                </div>

            </div>

            <hr />

            {/* <div className="row">
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://lh3.googleusercontent.com/proxy/MRdIx0AFj7P1bckOzVeUsuIOS-ZxJeTHcST76i1xzzEsRKvM1JmKSBZum5UtC5CBIDhlZTtSVDcvMXnY7Xqrl2M3Dtju5vsX07RTTfu7u8gWgom8FXNaj_TqJI7lcRvp94aPtFUEsic-cwFCB_OCXlLet978Jgm1G9oH" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://dotandline.net/wp-content/uploads/2019/06/Shinjiprofil-1024x642.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://i.pinimg.com/originals/f4/21/55/f421556eb56996e04295fc07133d0f72.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://upload.wikimedia.org/wikipedia/en/2/2f/Asukka13.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/a/a2/OP_C015_ritsuko.jpg/260px-OP_C015_ritsuko.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/9/9b/08_C071_kaji.jpg/260px-08_C071_kaji.jpg" alt="Uma Imagem" />
                    </div>
                </div>
            </div> */}


            <div className="row mt-4">

                <div className="col-12">
                    <h2 className="display-3 text-center">Clube da Luta</h2>

                </div>

                <div className="col-12 col-md-12 mt-2">
                    <p className="artigo text-justify lead">O filme se inicia com um homem que atinge seu limite, seu máximo para com a sociedade capitalista, repleta de regras e imposições, em outras palavras, o protagonista se desvencilha da realidade a partir do momento
que não mais a suporta, assim criando um alterego. Articular uma reflexão social e psicológica a respeito do filme clube da luta, explicitando a patologia transtorno dissociativo de identidade do personagem Jack e o
que levou o seu desenvolvimento. Análise bibliográfica, estudo crítico do filme clube da luta e leituras pertinentes para a colocação da patologia transtorno dissociativo referente ao protagonista da obra cinematográfica. O filme Clube da luta, que foi tomado por nós para analise, conta o drama vivido por Jack (Edward Norton), funcionário de uma grande empresa de seguros, que levava uma vida rotineira dedicada exclusivamente ao trabalho e se tornou fundador de um clube de luta livre clandestino, em parceria com Tyler Durden (Brad Pitt), ficando claro, ao longo da trama, que ele desenvolveu um processo de dissociação de sua personalidade. Tyler Durden e Jack eram duas faces de uma mesma pessoa. De acordo com o CID-10, o Transtorno Dissociativo de Identidade reflete um fracasso em integrar vários aspectos da identidade, memória e consciência. Cada estado de personalidade pode ser vivenciado como se possuísse uma história pessoal distinta, autoimagem e identidade próprias, inclusive um nome diferente. Em geral existe uma identidade primária, portadora do nome correto do indivíduo, a qual é passiva, dependente, culpada e
depressiva. A crítica ao drama acontece por sete principais vias: comprar, sofrer, apanhar, roubar, destruir, transar e encontrar o outro. O Jack tinha móveis caros em seu apartamento, o Tyler vivia esbanjando dinheiro
com carros esportivos e roupas de grife. Há um momento que os personagens apenas se sentiam vivos com o sofrer, para ter uma sensação de vivencia e autenticidade precisavam bater e apanhar. Há roubo na parte do carro no aeroporto e da gordura humana da clínica de estética para a produção de sabão. Quando se quebra ou destrói algo, parte-se do princípio de controle e torna-se agente de transformação do útil para o inútil. Quando há relação sexual se toca e é tocado, existe um gozo presente não apenas na ejaculação, mas na condição de se sentir vivo por estar conectado a algo ou alguém. O encontro com o outro se dá no sentimento que o Tyler afirma ter pela Marla, uma mulher cujo a busca do sofrimento para se sentir viva era a mesma que a dele. “Você queria um jeito de mudar a sua vida. Não podia conseguir sozinho. Tudo que quisera ser, este, sou eu. Eu pareço do jeito que quer parecer. Sou esperto, capaz, e mais importante, eu sou livre de todas as maneiras que você não é. [...] As pessoas fazem isso todos os dias. Falam consigo mesmas, veem-se como gostariam de ser, só não tem a sua coragem de, simplesmente, levar adiante. Você ainda se debate um pouco,
é por isso que às vezes você ainda é você. [...] Pouco a pouco, você está se transformando em... Tyler Durden.” (Clube da luta [Fight club], Direção David Fincher, 1999) A fala acima do alterego expõe como é a
dissociação, é uma criação de outra parte do indivíduo que critica o tempo todo, que aflige e controla. A resolução do Jack para o rompimento com o Tyler, dar-se quando ele consegue matar uma parte de si, se desvencilhar de tudo o que antes o preocupava, todo o questionamento do que ser, ter, fazer, comprar e de como a sociedade lhe impõe algo e como você realmente quer ser esse algo ou não. Refletindo e questionando não apenas o que é dado a nós e empregado, mas o que realmente queremos para nós.</p>
                </div>


               
             </div>
        </div>


    </Content>
