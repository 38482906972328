import React from 'react'
import {Switch, Route, Redirect} from 'react-router'

import home from './components/contents/home'
import movies from './components/contents/movies'
import politic from './components/contents/politic'
import me from './components/contents/me'
import euphoria from './components/articles/euphoria'
import evangelion from './components/articles/evangelion'
import fightclub from './components/articles/fightclub'
import attackontitan from './components/articles/attackontitan'
import criada from './components/articles/criada'
import junie from './components/articles/junie'
import breve from './components/articles/breve'
import music from './components/contents/music'
import watchmen from './components/articles/watchmen'
import oldboy from './components/articles/oldboy'

import espolio from './components/contents/espolio'
import watched from './components/contents/watched'
import history from './components/contents/history'
import writte from './components/articles/writte-me'
import allmovies from './components/articles/allmovies'

import topicos from './components/contents/topicos'

import games from './components/contents/games'
import wrapped from './components/contents/wrapped'



export default props=>
    <Switch>
        {/* exact = exatamente este path para ser encaminhado para este componente  */}
        <Route exact path='/' component={home}/>
        <Route exact path='/movies' component={movies}/>
        <Route exact path='/random' component={topicos}/>
        <Route exact path='/me/' component={me}/>
        <Route exact path='/music' component={music}/>
        <Route exact path='/espolio' component={espolio}/>
        <Route exact path='/watched' component={watched}/>
        <Route exact path='/history' component={history}/>


        

        {/* <Route exact path='/movies/euphoria' component={euphoria}/> */}
        <Route exact path='/movies/evangelion' component={evangelion}/>
        <Route exact path='/movies/fightclub' component={fightclub}/>
        <Route exact path='/movies/attackontitan' component={attackontitan}/>
        <Route exact path='/movies/criada' component={criada}/>
        <Route exact path='/movies/junie' component={junie}/>
        <Route exact path='/movies/oldboy' component={oldboy}/>
        <Route exact path='/movies/watchmen' component={watchmen}/>
        <Route exact path='/movies/breve' component={politic}/>
        <Route exact path='/wrapped' component={wrapped}/>


        <Route exact path='/to-inspire' component={writte}/>


        <Route exact path='/all-movies' component={allmovies}/>

        <Route exact path='/games' component={games}/>
















        {/* caso qualquer outra coisa seja escrita na url sera redirecionado para / indo para Home  */}
        <Redirect from='*' to='/'/>
    </Switch>