import React from 'react'
import Content from '../Content'
import { Link } from 'react-router-dom'

import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import evangelion from '../../assets/img/evangelion.jpg'

import './movies.css'

export default props =>
    <Content>
        <div class="jumbotron jumbotron-fluid bg-movies">
            <div class="container">
            </div>
        </div>

        <div className="container">
            <div className="row">

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/fightclub">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://images-na.ssl-images-amazon.com/images/I/61vKJHwfCUL._AC_SY606_.jpg" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4.5} readOnly precision={0.5} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h4 className="mb-2 text-decoration-none no-link text-dark">Clube Da Luta</h4>
                    <p className="menor">Filme sobre aqueles dias trabalhosos, cansativos e repletos de frustrações, mas que por algum motivo, encontramos motivos para perseverar, um homem sem propósito cria o seu.</p>

                    <Link className="" to="/movies/fightclub"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/attackontitan">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://m.media-amazon.com/images/M/MV5BMTY5ODk1NzUyMl5BMl5BanBnXkFtZTgwMjUyNzEyMTE@._V1_.jpg" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4.2} readOnly precision={0.2} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h5 className="mb-2 text-decoration-none no-link text-dark">Shingeki no Kyojin</h5>
                    <p className="menor">Mundo apocalíptico, titãs como pessoas deformadas de 20 metros, correm atrás do povo e as devora, artefactos de movimentos, láminas e muita doidera, é a unica forma de mata-los. Boas soundtracks, plots e história bem construida.</p>

                    <Link className="" to="/movies/attackontitan"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>

            </div>

            <div className="row">

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/euphoria">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://pbs.twimg.com/media/D6eCvb5VUAADyBJ.jpg" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={3} readOnly precision={0.5} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h4 className="mb-2 text-decoration-none no-link text-dark">Euphoria 2019 HBO</h4>
                    <p className="menor">Uma série onde pessoas de 25 anos fingem ter 16, niguém precisa estudar ou trabalhar e todos os problemas giram em torno de nudes, drogas, competiçoes e abusos, de fato o sonho de qualquer adolescente trouxa moderno...</p>

                    <Link className="" to="/movies/euphoria"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/evangelion">
                        <div class="card test" >
                            <img class="card-img-top movie" src={evangelion} alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4} readOnly precision={0.5} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h5 className="mb-2 text-decoration-none no-link text-dark">Neon Genesis Evangelion </h5>
                    <p className="menor">Essência da arte de animação pré anos 2000, bom humor, bons sentimentos, flertes envolventes, história 9/10.</p>

                    <Link className="" to="/movies/evangelion"><a className="d-flex align-items-end" href="">Ler Crítica...</a></Link>

                </div>


            </div>


            <div className="row">

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/oldboy">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://i.pinimg.com/originals/f2/74/50/f27450ec8280d41cb1a1fc1b41c72742.png" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4.8} readOnly precision={0.1} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h4 className="mb-2 text-decoration-none no-link text-dark">Oldboy</h4>
                    <p className="menor">Em Breve</p>

                    <Link className="" to="/movies/oldboy"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/breve">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://3.bp.blogspot.com/-APtMGAit6dY/UAokmQ0RzPI/AAAAAAAAA0U/R-MybNqRGeY/s1600/500+dias+com+ela.jpg" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4.3} readOnly precision={0.1} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h5 className="mb-2 text-decoration-none no-link text-dark">500 dias com ela</h5>
                    <p className="menor">Em Breve</p>

                    <Link className="" to="/movies/breve"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>


            </div>

            

            <div className="row">

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/watchmen">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://braavos.files.wordpress.com/2013/01/watchmen-o-filme-dvd-dublado-em-portugus-super-herois-dc_mlb-f-2822722751_062012.jpg" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4.5} readOnly precision={0.5} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h4 className="mb-2 text-decoration-none no-link text-dark">Watchmen</h4>
                    <p className="menor">O melhor filmes de super herois</p>

                    <Link className="" to="/movies/watchmen"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/breve">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://images-na.ssl-images-amazon.com/images/I/81rGFvzdoAL.jpg" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4.4} readOnly precision={0.2} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h5 className="mb-2 text-decoration-none no-link text-dark">The Mandalorian</h5>
                    <p className="menor">Em Breve</p>

                    <Link className="" to="/movies/breve"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>


            </div>

            <div className="row">

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/breve">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://limaomecanico.com.br/wp-content/uploads/2019/07/a-rede-social.jpg
" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4.5} readOnly precision={0.5} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h4 className="mb-2 text-decoration-none no-link text-dark">A Rede Social</h4>
                    <p className="menor">Em Breve</p>

                    <Link className="" to="/movies/breve"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>

                <div className="my-2 col-lg-3 col-6 text-center">
                    <Link className="" to="/movies/breve">
                        <div class="card test" >
                            <img class="card-img-top movie" src="https://1.bp.blogspot.com/-E18kjCnBLEA/WoHOZrAzqwI/AAAAAAAAAb4/bd-Uw0orf6o4ZxlzhTYqDEHd7KgGqtYMwCEwYBhgL/s1600/20180212_151834.jpg" alt="Uma Imagem" />
                        </div>
                    </Link>

                    <div className="my-2">
                        <Box className="" component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Nota</Typography>
                            <Rating name="read-only" value={4} readOnly precision={0.2} />
                        </Box>
                    </div>

                </div>
                <div className="my-2 col-lg-3 col-6 flex-column">

                    <h5 className="mb-2 text-decoration-none no-link text-dark">O Profissional</h5>
                    <p className="menor">Em Breve</p>

                    <Link className="" to="/movies/breve"><a className="d-flex align-items-end" href="">Ler...</a></Link>

                </div>


            </div>

            
        </div>



    </Content>
