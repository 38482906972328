import React from 'react'
import Content from '../Content'
import './allmovies.css'


export default props =>
    <Content>
      <div class="slideshow-container">

        <div class="mySlides fade">
          <div class="numbertext">1 / 3</div>
          <img className="w-100" src="https://i.pinimg.com/originals/7c/f9/6e/7cf96e5878cc8aad3e704b81150d4d47.png"/>
          <div class="text">Caption Text</div>
        </div>

        <div class="mySlides fade">
          <div class="numbertext">2 / 3</div>
          <img className="w-100" src="https://i.pinimg.com/originals/7c/f9/6e/7cf96e5878cc8aad3e704b81150d4d47.png"/>
          <div class="text">Caption Two</div>
        </div>

        <div class="mySlides fade">
          <div class="numbertext">3 / 3</div>
          <img className="w-100" src="https://i.pinimg.com/originals/7c/f9/6e/7cf96e5878cc8aad3e704b81150d4d47.png"/>
          <div class="text">Caption Three</div>
        </div>


        <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
        <a class="next" onclick="plusSlides(1)">&#10095;</a>
      </div>
      

      <div className="text-center">
        <span class="dot" onclick="currentSlide(1)"></span>
        <span class="dot" onclick="currentSlide(2)"></span>
        <span class="dot" onclick="currentSlide(3)"></span>
      </div>

    </Content>
