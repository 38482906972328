import React from 'react'
import Content from '../Content'
import './history.css'


export default props =>
    <Content>
        <div class="jumbotron jumbotron-fluid bg-politic">
            <div class="container">
                <h1 class="display-4"></h1>
                <p class="lead"></p>
            </div>
        </div>


       <div className="container">

            <div className="row mx-3 my-5">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            {/* <img class="card-img-top " alt="Uma Imagem" /> */}
                        </div>
                        <div class="card " >
                            {/* <img class="card-img-top " src={} alt="Uma Imagem" /> */}
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Black Mirror : The Entire History of You</h4>
                        <p className="menor artigo lead">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis convallis convallis tellus id. Vestibulum morbi blandit cursus risus. Sollicitudin tempor id eu nisl nunc mi ipsum. Sollicitudin ac orci phasellus egestas. Dolor magna eget est lorem ipsum. Consequat interdum varius sit amet. Mauris commodo quis imperdiet massa tincidunt nunc. Nulla facilisi morbi tempus iaculis urna. Praesent elementum facilisis leo vel fringilla est ullamcorper. Est velit egestas dui id ornare. Massa enim nec dui nunc mattis. Bibendum ut tristique et egestas quis ipsum suspendisse ultrices. Eget arcu dictum varius duis. Fringilla ut morbi tincidunt augue. Lacinia at quis risus sed vulputate odio ut enim. Lacinia at quis risus sed vulputate odio ut.
                        </p>
                    </div>
                    <div className="my-lg-2 col-lg-3 col-12 flex-column ">
                        <p>dasdasdsa</p>
                        <div className="text-center">
                        <iframe src="https://open.spotify.com/embed/track/4EKMLcDmI7I5bORpsWgQg1" width="250" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />
        </div>




    </Content>
