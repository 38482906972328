import React from 'react'
import Content from '../Content'
import './music.css'


export default props =>
    <Content>
        <div class="jumbotron jumbotron-fluid bg-politic">
            <div class="container">
                <h1 class="display-4"></h1>
                <p class="lead"></p>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-12">
                    <a href="https://open.spotify.com/user/niibiru" target="_blank">
                        <div className="text-center">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/2048px-Spotify_logo_without_text.svg.png" alt="" height="60" />
                            <h5 className="mb-2 text-decoration-none no-link text-success ">Visit my Spotify</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <hr />

        <div className="container">
            <div className="row">
                <div className="col-12 text-center">

                    <h3 className="text-success">Música que estou ouvindo neste instante...</h3>
                </div>
                <div className="col-12 col-md-4 text-center mt-2 none">
                    <a href="https://spotify-github-profile.vercel.app/api/view?uid=niibiru&redirect=true" target="_blank"><img className="img-fluid" src="https://spotify-github-profile.vercel.app/api/view.svg?uid=niibiru&cover_image=true&theme=default&bar_color=ede8ee&bar_color_cover=false" alt="" /></a>
                </div>


                <div className="col-12 col-md-4 text-center mt-2">
                    <a href="https://spotify-github-profile.vercel.app/api/view?uid=niibiru&redirect=true" target="_blank"><img className="img-fluid" src="https://spotify-github-profile.vercel.app/api/view.svg?uid=niibiru&cover_image=true&theme=default&bar_color=ede8ee&bar_color_cover=false" alt="" /></a>
                </div>


                <div className="col-12 col-md-4 text-center mt-2 none">
                    <a href="https://spotify-github-profile.vercel.app/api/view?uid=niibiru&redirect=true" target="_blank"><img className="img-fluid" src="https://spotify-github-profile.vercel.app/api/view.svg?uid=niibiru&cover_image=true&theme=default&bar_color=ede8ee&bar_color_cover=false" alt="" /></a>
                </div>
            </div>

            <hr />

            <div className="row text-center mt-2 mb-2">
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/album/4LH4d3cOWNNsVw41Gqt2kv" target="_blank"><img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02f05e5ac32fdd79d100315a20" alt="Pink Floyd" /></a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/75pQd26khpV9EMVBRIeDm6" target="_blank"><img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e021e838c3997685abe372238da" alt="MARINA" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/artist/16oZKvXb6WkQlVAjwo2Wbg" target="_blank"><img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e023932e4fc8c9e69f72e6ec994" alt="The Lumineers" /></a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/artist/1hzfo8twXdOegF3xireCYs" target="_blank"><img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e0223444c767d67417474f103e3" alt="Milky Chance" /></a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6gVGdTdzRWSMDeFt7silw0" target="_blank"><img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02d30e619b4c7dad2da5ba3e95" alt="Fever Ray" /></a>

                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/artist/7Ln80lUS6He07XvHI8qqHH" target="_blank"><img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e024ae1c4c5c45aabe565499163" alt="Arctic Monkeys" /></a>

                    </div>
                </div>
            </div>
            <div className="row text-center mt-2 mb-2">
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/5kCWUfblMC2xdBruW7PeuC" target="_blank"> <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ceb24a1906254750e82c1db7" alt="Untrust Us" /></a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/artist/1moxjboGR7GNWYIMWsRjgG" target="_blank"><img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02003f81b1f4246362638e682d" alt="Florence" /></a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/artist/3yY2gUcIsjMr8hjo51PoJ8" target="_blank"> <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ada101c2e9e97feb8fae37a9" alt="Uma Imagem" /></a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/artist/3TNt4aUIxgfy9aoaft5Jj2" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ca5dd35539f06aaf585ef769" alt="Yeah Yeah Yeahs" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/artist/3qm84nBOXUEQ2vnTfUTTFC" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e0268384dd85fd5e95831252f60" alt="Guns And Roses" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/artist/00FQb4jTyendYWaN8pK0wa" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02ebc8cfac8b586bc475b04918" alt="Uma Imagem" />
                        </a>
                    </div>
                </div>



                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/5NORWMFC27ywGSZxi8uquP" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02e4c3db3e7ebfc22bc080f334" alt="Ophelia" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3dYD57lRAUcMHufyqn9GcI" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2737a9bf5f82e32d33d4503fe7b" alt="Take me to Church" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0uVyZywUNOp6S0dU5r8TS8" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273ec67f6776573462a1ad9ee2d" alt="Player in C" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3YuaBvuZqcwN3CEAyyoaei" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273a7292b6863258e889b78d787" alt="Like a Stone" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3VAeTjREoKPY1exOXR4oBm" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273488f7916bcb14082e1acb101" alt="Go fuck Yourself" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4dDZyGxClFiOBUjcpxhXWg" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2736f4f62da3d811b6501a69ffa" alt="I Hurt My Self Today" />
                        </a>
                    </div>
                </div>



                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/511ndT2nNWnvAzPdzo8wBO" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273621489fd90d50158b3714cb0" alt="Mad World" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6mcxQ1Y3uQRU0IHsvdNLH1" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273626ad9bb7d5f84c59652b161" alt="Where Is My Mind" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7MiZjKawmXTsTNePyTfPyL" target="_blank">                        <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273ecdad1ee6d530eb965d7c51e" alt="Devil Eyes" />
                        </a>
                    </div>
                </div>



                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7ouMYWpwJ422jRcDASZB7P" target="_blank"><img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27328933b808bfb4cbbd0385400" alt="Supermassive Black Hole" /></a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1DehtVbbTjUQjxGaXd5PnH" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2732dfa7761d4918777330d9794" alt="Drifter" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/76bcaYeKOKTrj9lEc9BZFU" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273c4e34b698ba86fc02589b447" alt="Rubel" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1dX6oGAG5PaexuPj29PsYd" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273f793a842c1642b09a768a63b" alt="I Cant Go on Without You" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2RtNc0pKETU7tHweBeqM0X" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2735e586c9004ed2100d5a20427" alt="Numb MARINA" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/5xhQChGGhKLWqBqX4XhtYE" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2735a41b6c530b167ffba6a92f5" alt="sail" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0TI8TP4FitVPoEHPTySx48" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2739683e5d7361bb80bfb00f46d" alt="Iron Man" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7lQWRAjyhTpCWFC0jmclT4" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2737632fe25cd368dc205927f0c" alt="Gangstar Paradise" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6F6DxQ3XjToEHX1e2KKSbg" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273ed9531fd3add18804cd6d7c1" alt="Simple Man" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2aibwv5hGXSgw7Yru8IYTO" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27309fd83d32aee93dceba78517" alt="Snow - Red Hot Chili Peppers" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4mg67iTXA05DgUyTjtmONx" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273a0b037c0f0124a47406781fb" alt="Simonami" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2WRFD9WczJ975X2K1Y9YVs" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273e922211eede5ae8017ff802f" alt="Heads Will Roll" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1zuKjpp4t7BS8JPKi6mkQr" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27348a95897fd91eb34032a93e3" alt="The XX" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/44XBLYMVEt5vJcb5qXfQ7V" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2735c9890c0456a3719eeecd8aa" alt="Alejandro" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7gARZ2i5u5DHC1b1n1fML5" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2731b6dedfcf8b05b06b6bd002f" alt="Breezeblocks" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3XHrTm6WE2BOHafLwTT3GR" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273c403c5700c5a0d6f2fe2d8f1" alt="Eyes on Fire" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/44uOreAESeRIBaxkuCW4wN" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273e4c3db3e7ebfc22bc080f334" alt="Sleep On The Floor" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1JLn8RhQzHz3qDqsChcmBl" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2733e030a7e606959674643d274" alt="I Want Know Whats Love Is" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1QV6tiMFM6fSOKOGLMHYYg" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273e691217483df8798445c82e2" alt="Poker Face" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7F25roCtYi55JouckaayPC" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273a5d31644260279be8d0c46c0" alt="Judas" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0fYVliAYKHuPmECRs1pbRf" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27352e8aa3275f7a3ee9c1e3628" alt="Renegades" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/5g1rzUEyVFNr2i4ifEjlVN" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273752b3b4f87f1193c7f2b6b60" alt="Love Hurts" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3axp4dcei5PDShQZTCRdn1" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2738535ab4ff39a6f7f4a622931" alt="Dressed In Dresden" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4bJygwUKrRgq1stlNXcgMg" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2733b27e1cd8a6bc6804c2e5fd2" alt="All The Things She Said" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6WK9dVrRABMkUXFLNlgWFh" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2730a7d45a345534966a4ad2c39" alt="Enjoy The Silence" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/57iDDD9N9tTWe75x6qhStw" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273707d13d3f87652e737e94d45" alt="Bitter Sweet Symphony" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1kFY2HhQCMs7W6CBH0M6wj" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2735cffcf384073b8aae7f81d2e" alt="Asia" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1rQ0nEmdvzdA2FpMo3vrrg" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27350ef398d1769f69dbd28a894" alt="Scoot Pilgram X World Arruined By Generation of Woman" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1i6N76fftMZhijOzFQ5ZtL" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2733711fa074510441f17c0d594" alt="Physco Killer" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0boS4e6uXwp3zAvz1mLxZS" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273216fa486e2c3e899cacfe6bf" alt="Run Boy Run" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6mFkJmJqdDVQ1REhVfGgd1" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273d8fa5ac6259dba33127b398a" alt="Wish You Were Here" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7mf1hxau7AkuXEYbqHrOWh" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273f472c5248f5e854610fae9d7" alt="Vanessa" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/48p1tayyXF3eh8E3Y4pXmK" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273918fee4073aa392349123e63" alt="Genesis" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1BYZxKSf0aTxp8ZFoeyM3d" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273eb13fbc012815ea6e834e842" alt="girl in red" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/03wqvxOYgomDNUWTzesadS" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273f51866ac28e66201a645c990" alt="Sons" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7M3HuQRRAm1QD4kftmq0bb" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273fb35bd51df43765ef89a13ce" alt="amour plastique" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4LCtMu7AcHqynV8kETB0av" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2730349241dbffd84ca85730709" alt="Vietnam" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7svpAkwc6xaSxlbZ7V7JiS" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27375398280edcb0278b4516c48" alt="i was all over her" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/74SUn8w4lYSwgOGPvVZiEo" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273f134621be38f4fdabbf33442" alt="gallowdance" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7frbEieYWBurZsJuRDJFbS" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273236366dbebed7792b39e7d3c" alt="girl revange" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2IqjKEBiz0CdLKdkXhxw84" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273d134dbb0fad78dda23d777ff" alt="retrograde" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7cs7MLtAWvsdf3w5kBvfK2" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273b8a737ba46a47867b2bb1d96" alt="cigarretes after sex" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1BDKqhEFRW5d9Y5CVXNUL1" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27392d21aef6c0d288cc4c05973" alt="You Could Be Mine" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3Cc0xsYtoaPDjVZyiPHiQ4" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2736c3207ba7949291a8114b491" alt="Vendredi Sur Mer" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/51QEyJI5M7uyd8DOh9tqQY" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273187331e276c898d39764cc98" alt="Lorde" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0jJLKTprMs5AjM3Nn433D7" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273389f3756fd3ab9f273789f7e" alt="Night Light" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/58e7V70Em6FABOiln4jNoZ" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2738b790285e99ecefd854842da" alt="Mauvais Prèsage" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0hDQV9X1Da5JrwhK8gu86p" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2731b1cb4ef0f096f9d66fc3dc6" alt="Maps" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2usbuL20o8yDFqGbszpwqN" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273f90c0795294eed818d5c7b76" alt="You're So Cool" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2LKOHdMsL0K9KwcPRlJK2v" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273b492477206075438e0751176" alt="After Dark" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4EbMgU3Iidw5Mf6LVEAka7" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273fd5a5bfaff9b2b54799279c5" alt="nao sei" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/26ZzSLUJMmYp5u1hDELEWH" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27339140b04426a6ae1c7842e38" alt="Transgender" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1VNWaY3uNfoeWqb5U8x2QX" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273f5e7b2e5adaa87430a3eccff" alt="Lonely Day System Of A Down" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2xierqeEyEkSJL9WKInBR5" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273419b6bc35bd5a247cd08dea5" alt="Marry me" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/31U4cXm2ikC42NKe6wNoSv" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273ab72185e9dea4268e12fcb9f" alt="Subway" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3cUxncrTWSA9lhlQbuIwUY" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273672951d44a35cb08092f2c1f" alt="Stuck on the puzzle" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3y29QLWHxB1G7CfuFyZ8qe" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273c8cd1dafff9562ac2f73f0b6" alt="I'm  that guy" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3ZRWcArefd1sfpdw5icCM7" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2738ca5132958ec80a0917a9816" alt="Exagerado" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6jOpA0UG23yw4MyXQKa6iz" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273c76cdf248a2090e005ee8140" alt="Billie Holiday" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6NFKy0jg3S6x380iuF47jX" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27320b19a218a86e6946387f56e" alt="He Is" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6pGkkjvtWn3PQRenzNqXDb" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273a3eb12ececd0d1297e3a4526" alt="Miracles" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1SLXLXCh1Xfp7toadPmZ8H" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27322f6119310c53bfc73ca1716" alt="nao sei" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6CTriAbhB9UuZZomIfAFn9" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273762d48f2702260b5fc87b54f" alt="nao sei" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/50CgC2wKmbWm4WLQKY87oP" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273897cd4eeff3174110aa6230b" alt="In Motion" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4btNg6ffbom5dvlS5NLLZz" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2737f629e9e1e57104d38d5a146" alt="The Theme of Sachio" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4v1XJpjwCRNCTZ3mPU96Jp" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2735ae244507d2e2be21c509227" alt="Midnight Blue" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6IfPyMb0Sxptpx6jBUATOS" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2731624590458126fc8b8c64c2f" alt="Old Money" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2MHQTJmaAQ8mC4DUBcq2Hd" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273138f62a6d1098cb76ce47c40" alt="Shoom" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2MgPVcBvbw9y9R8tC3aH5q" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273d73dab235e645690f91769dc" alt="Cabaret Nocturne" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/068LmtzI6xJJDPfPnMje0U" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2739e1cfc756886ac782e363d79" alt="Let it Happen" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2YEa3nFylD0cX3RZ48IJol" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2736904b550aa9c870b3419e4b8" alt="Goat" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/5YzckYO7G171MHg1UqkxJY" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2738debb6be8b28564b07361cd8" alt="Destroy Everthing you Touch " />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0gMZiidKSrW8oIuXTlZWDv" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273954e2e6afecfc7553ec610cf" alt="I Monster" />
                        </a>
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3t9AQbyfGnVbe3xuTg9PEX" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2736c3207ba7949291a8114b491" alt="La femme à la blue" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0E4aN4YTfP7x4Ymr3PjKOW" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273064028f97fe01d07a15729eb" alt="Rone" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2OFrAsP5Vrcb0DtsPNsm54" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2731e07e60dea6e2c877ea3b2b4" alt="My Kind of Woman" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1FvDJ9KGxcqwv1utyPL3JZ" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273a09b231129ab1cb1a6efc57f" alt="The Smiths" />
                        </a>
                    </div>
                </div>



                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0BmT0BVnYYggf7V4NCtx2B" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e027da3504b2ba9f1ecb43e0ac2" alt="Adam Lambert" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6ilOjlJMBMSAWolY3xHZ1i?si" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2738b32b139981e79f2ebe005eb" alt="Physical" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1jJci4qxiYcOHhQR247rEU?si=c64eb5e628df49bb" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273fb4736580ccb6f1997c0842d" alt="Monotomia" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2qpacEyFxmbxCpIEqZkqvC" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27327e52df284591f859ce71b52" alt="Sports" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4cOIgq2jOuGIOsAlBcPHQf?si=e13cccf4a62f4fee" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2735a721e58cfabeb2b22839e59" alt="Comatose" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/1QFh8OH1e78dGd3VyJZCAC?si=5405ee7b240c4e88" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273ca40908d43a2a80fb34de8a3" alt="Boys Dont Cry" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/74n118P9h9Sz2AwDSc3f8N?si=15d926f0b69e417b" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2733ba1f72fb3bf152db4547435" alt="Jupiter 4" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3jjsRKEsF42ccXf8kWR3nu?si=6dc9d8d69b884862" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273c428f67b4a9b7e1114dfc117" alt="Mitski" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3wSEuaaOZe8LBV35uRBwIo?si=09f261d058784bef" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273730f2b52511a8b4ba3a8b624" alt="Chaos A.D" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3Bj2mrlp3tALHO5U3mK8zM?si=6947d793da074c2b" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273129f56c6c0cadcab36abfc70" alt="Blade Runner 2049" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6IdkdL2EwVmeH9NrNJwdEE?si=f514d299579c4e0c" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273fca347d8b1366b6ff8134a9f" alt="OZORA" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/3sslYZcFKtUvIEWN9lADgr?si=bdbb23d2959c47db" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273e90db8983ebd43b776694179" alt="First Love" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/0pASR7G5lcaDpG6WS94LWN?si=5c77993d76b14e1e" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273b366a18aeb68dfc79bd89d22" alt="Im wering his jacket" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4BP3uh0hFLFRb5cjsgLqDh?si=8c944c4a179a4e4a" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2739f39192f9f8ca1c90847b3e5" alt="Creedence" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/5RBOcBpJXaNnHCGViJmYhh?si=a566420d951c418f" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2733d54a30e59638f60f56dd4e6" alt="Perfect Girl" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2cSRuejq6DU9U6OkSmUw17?si=7810bf14fed34cae" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2730b52377b2466d5f5552800ae" alt="Desire" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/5mnpMkzp3Z2RCCbwIEZCbv?si=6940dcfaa8ef4c12" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e02f13c87b29acce422dbab4cac" alt="Incel 1" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4kTLpAbhuEGHAAdDjOIWaa?si=436790d5b0064fc4" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273713f297a7bdc1d48971062b2" alt="Oh Sigma" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/2X0wU5wniSmslkpN4raRWu?si=4083004d9b8a4ae2" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273250d5af3fb1c62068d23cd4f" alt="Russe1" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6nqdgUTiWt4JbABDurkxMI?si=4021161910dd4f6b" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27383bd1e120f8b14407fe73816" alt="Sigma--" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7gJKrxhUIg4CRda1nkq5aj?si=d3d0ba189d434d83" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273d6d8c2eaa1f9031b62f7a3f7" alt="Pacifc" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/5fbQCQt91LsVgXusFS0CCD?si=86ef781ea9504b46" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b27316eb1e685e6bd37ab3228de6" alt="Disorder" />
                        </a>
                    </div>
                </div>


                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/7pM9cLbrgRGX1hHElTX92m?si=dfe99904df1a4fa6" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2734e7be339cee55cf14e6bc50f" alt="Acid" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/20ztml2STRF7Sq1UaBB6ox?si=ea69d257bb55447d" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b2737be0bdb1f8731f9af3e95812" alt="Perturber" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4AblNv2IZzFJnnifSzb9v5?si=e30936dfbf774c2e" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273b9f7bba3d28fab3e964b3fdd" alt="Lipstick" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4LCtMu7AcHqynV8kETB0av?si=b94a5c53baac489d" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d00001e020349241dbffd84ca85730709" alt="Sports" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/4yyX0nJqMpqaubcGdGsxx9?si=439fbedae82148a2" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273d7859b708f662c3018f213b8" alt="ODIUM" />
                        </a>
                    </div>
                </div>

                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <a href="https://open.spotify.com/track/6mcxQ1Y3uQRU0IHsvdNLH1?si=981bdfaf64fa4c48" target="_blank">
                            <img class="card-img-top" src="https://i.scdn.co/image/ab67616d0000b273626ad9bb7d5f84c59652b161" alt="Oh God" />
                        </a>
                    </div>
                </div>



            </div>
            <hr />
            

            <div className="row mb-5">
                <div className="col-md-4 col-12">
                    <iframe width="350" height="250" src="https://www.youtube.com/embed/DfxQj4jKJ0E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <div className="col-md-4 col-12">
                    <iframe width="350" height="250" src="https://www.youtube.com/embed/aNoO8BEw7qY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <div className="col-md-4 col-12">
                    <iframe width="350" height="250" src="https://www.youtube.com/embed/vN0xfXqOPfc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                </div>
            </div>
        </div>

    </Content>
