import React from 'react'
import Content from '../Content'
import './watched.css'


import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom'

import theEnterieImage from '../../assets/img/historyofYou.png'
import eyesBlackMirror from '../../assets/img/eyesblackmirror.gif'
import crynosdiveblackmirror from '../../assets/img/crynosdiveblackmirror.gif'
import nosedive2 from '../../assets/img/nosedive2.gif'


export default props =>
    <Content>
        <div class="jumbotron jumbotron-fluid bg-watched" ></div>
        <div className="col-12 my-2">
        </div>


        <div className="container">
            <div className="row">
                <div className="col-12">
                    <a href="https://letterboxd.com/Agnoos/" target="_blank">
                        <div className="text-center">
                            <img src="https://a.ltrbxd.com/logos/letterboxd-mac-icon.png" alt="" height="60" />
                            <h5 className="mb-2 text-decoration-none no-link text-dark ">Visit my Letterboxd</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <hr />

        <div className="container">

            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src={eyesBlackMirror} alt="Uma Imagem" />
                        </div>
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/3579ab84ea5d4aeda29d4aa8f1002576/0421b4a3ccd67499-58/s400x600/3ae076c0e091f0e5425455387f97b6ff854ecf7f.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Black Mirror : The Entire History of You</h4>
                        <p className="menor artigo lead">
                            meu deus que vontade de morrer, como pode tamanha tragedia, porque esse sentimento mata a gente ? meu deus que tragedia meu deus </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2" component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={3.5} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/4EKMLcDmI7I5bORpsWgQg1" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/1b89b37fb45d9e8f5b476868adf285cd/e3ba7026b65ee3da-95/s400x600/3228d182c7eabd022187433cff2d9b033a9e070d.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/62d7409e30e93a86eeabb718b6e0d15e/tumblr_py186nSaF41s61ireo5_r1_400.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">The Social Network</h4>
                        <p className="menor artigo lead">
                            Ah, esse filme é uma mastery pra mim, David Fincher é um poeta em seus roteiros e diálogos. Como desenvolvedor de software, sou suspeito por gostar desta biografia do Zuckerberg, mas não se trata de tecnologia ou genialidade, se trata da “Rede Social”, um trocadilho entre o que esse cara criou e por quem ele precisou passar por cima, sócios, amigos e amores.

                            Um puta filme sobre empreendedorismo, realidade e negócios.
                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={4.5} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/0GO1QWs0YvDmmoTHUqitvf" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            {/* <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/b04610dbfee3e05c494fd8911f5835af/tumblr_pwzwbqmJqp1rrkahjo1_400.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/45449861681dff130f6dbe9b3f240f21/tumblr_pemhugzMSt1w67ki0o7_r1_400.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Taxi Driver</h4>
                        <p className="menor artigo lead">
                            De Niro é muito bom nesse filme, ele transmite em essência crises existenciais comuns da contemporaneidade, principalmente relacionados a personificação masculina. Mentalmente abandonados, financeiramente arruinados e incapazes de se relacionar. É um Clube da Luta de um homem só, Taxi Driver é íntimo. Explorar a mente deturpada de um homem sozinho.                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={4.0} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/10nyNJ6zNy2YVYLrcwLccB" width="260"   frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row mx-3 my-5 justify-content-between justify-content-between">
                <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                    <div class="card" >
                        <img class="card-img-top " src="https://64.media.tumblr.com/2107f2d18dce0b70760e3647286efe6f/5f2e15236fe78bab-7b/s400x600/0140523e5c610ac40f99472373e95dcf1b161586.gifv" alt="Uma Imagem" />
                    </div>
                    <div class="card " >
                        <img class="card-img-top " src="https://64.media.tumblr.com/59aa4df91bcdbe0c834b70ab02d8fbcb/feb998e373969e78-9e/s400x600/1ab0bbc076a21443b6b2e95dc2b2632035bca626.gifv" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                    <h4 className="mb-2 text-decoration-none no-link text-dark ">The Lobster</h4>
                    <p className="menor artigo lead">
                        meu deus eu sou tao só...
                    </p>
                </div>
                <div className="col-lg-3 col-12 ">
                    <Box className="text-center my-2" component="fieldset" mb={5} borderColor="transparent">
                        <Typography component="legend">Minha Nota:</Typography>
                        <Rating name="read-only" value={4.7} readOnly precision={0.1} size="medium" />
                    </Box>
                    <div className="text-center">
                        <iframe className="spotifySize" src="https://open.spotify.com/embed/track/6kBzkRsNjSr7fbEHDvVCYR?utm_source=generato" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                </div>
            </div>


            <hr />
            {/* <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/67e54459bb14365764868218ddd1cfa4/tumblr_p270f4qsae1vw9ocwo1_1280.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/b4136daedcab2e736dafe7131f6fddcd/tumblr_og8pddP15v1spaqz2o6_400.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Black Mirror : Nosedive</h4>
                        <p className="menor artigo lead">
                        Nosedive é necessário, tenho uma consideração especial por esse episódio por estar em contato com pessoas que vivem essa “realidade”. O desejo de se sentir aprovado pelas pessoas. Dedicação pela aparência perfeita, em dar tudo de sí para estar em um pódio de status inútil onde pessoas fingem ser felizes.</p> <p className="menor artigo lead"> Uma queda livre para lembrar de não se importar..
                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={3.5} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                        <iframe className="spotifySize" src="https://open.spotify.com/embed/track/78LDHXyUL8fnbdIcrf9x9V" width="260"   frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr /> */}



            {/* 

            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/8297946f3adbc5b64aaa5f5fd638224b/8b688e795b366081-68/s400x600/cc26d9e3722d9dc113cc026be18c3b4029c80d0c.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/4cd7d87597bc70e89b12c66ef35cccbd/tumblr_p8icqd1rOq1xqlc78o7_r1_400.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Black Mirror : San Junipero</h4>
                        <p className="menor artigo lead">Já deu pra ver que eu gosto de Black Mirror, esse episódio aqui é o mais apaixonante e carismático. No final te dá um embate de emoções.</p> <p className="menor artigo lead"> Foram lágrimas indecisas que saíram de mim, triste pela realidade, às vezes cruel, mas feliz pela possibilidade da aceitação.</p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={4.0} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                        <iframe className="spotifySize" src="https://open.spotify.com/embed/track/37Q5anxoGWYdRsyeXkkNoI" width="260"   frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr /> */}



            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/282f2aa013a040b53a1ba99ea8e4a7d3/7f2029e7a9b8a01f-46/s400x600/96344f93f8e68abf961633cec189637f08aa9efb.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/75e5dcca8553fb39d690b38e22bf0056/7f2029e7a9b8a01f-21/s400x600/726d40aab9c4ce04ac8eae407284287b1ec7a950.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Eternal Sunshine of the Spotless Mind</h4>
                        <p className="menor artigo lead">
                            É um filme bonito, gosto dos protagonistas e a forma com que incorporam o personagem. O primeiro contado, diálogo e flerte; não é emocionante ? pra mim é rs, assim como o caos que se estabelece durante o tempo juntos.</p> <p className="menor artigo lead"> Detalhes que incomodam, crises existênciais que deprimem, momentos de impulso que não podem ser revertidos... Mas talvez seja isso, amor como algo a se cultivar, a se construir, e se não der certo, tudo bem...
                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={4.2} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/4AQwwsz0ztRx4YKNb1GdBB" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />


            {/* <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/282f2aa013a040b53a1ba99ea8e4a7d3/7f2029e7a9b8a01f-46/s400x600/96344f93f8e68abf961633cec189637f08aa9efb.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/75e5dcca8553fb39d690b38e22bf0056/7f2029e7a9b8a01f-21/s400x600/726d40aab9c4ce04ac8eae407284287b1ec7a950.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Eternal Sunshine of the Spotless Mind</h4>
                        <p className="menor artigo lead">
                            É um filme bonito, gosto dos protagonistas e a forma com que incorporam o personagem. O primeiro contado, diálogo e flerte; não é emocionante ? pra mim é rs, assim como o caos que se estabelece durante o tempo juntos.</p> <p className="menor artigo lead"> Detalhes que incomodam, crises existênciais que deprimem, momentos de impulso que não podem ser revertidos... Mas talvez seja isso, amor como algo a se cultivar, a se construir, e se não der certo, tudo bem...
                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={4.2} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/4AQwwsz0ztRx4YKNb1GdBB" width="260"  frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr /> */}



            {/* <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/5d84ecc78d6e775d240b65e3c854c4fb/tumblr_ofopddQGUO1r6l2t6o2_1280.png" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/6514d1550045bed2a0c312210cfe4346/tumblr_p2t6i0FVx91wtdagjo3_400.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Black Mirror : Shut Up and Dance</h4>
                        <p className="menor artigo lead">Esse é o episódio black mirror, o mais black mirror de todos, começamos de uma forma, criamos toda uma expectativa e no decorrer somos massacrados por inúmeras reviravoltas. O roteiro tenta mascarar diversas características, e de certa forma desconfiamos das coisas, mas duvidamos mesmo assim. Alex Lawther naturalmente já transparece um estereótipo clássico de adolescente meio estranho, e nesse parêntese, acabamos descobrindo muitas coisas sobre ele e outras pessoas, que passarão a tapar suas câmeras com fita isolante...
</p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={3.1} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                        <iframe className="spotifySize" src="https://open.spotify.com/embed/track/0z1o5L7HJx562xZSATcIpY" width="260"   frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr /> */}


            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/fea3d60d8f0b9cf47d3ea3515d180d3c/tumblr_pcynb1xZXY1v4a8wfo1_1280.png" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/1ffe1a9430a3e04282af4370f4955e1c/tumblr_oxmpiw3Tjl1w67ki0o4_540.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Incendies</h4>
                        <p className="menor artigo lead">
                            Incêndios é um filme bastante impactante. Qualidade de som e imagem ótimos, sua proximidade aos cenários de guerra impecável.</p> <p className="menor artigo lead" > Não quero falar muitos detalhes, dificilmente dou 5 estrelas em algo, mas essa é do tipo que só se assiste uma vez, não é fácil engolir a realidade destruidora da guerra, não é fácil viver sem esperanças.
                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={5} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/7Hg6a7tZsVTkXnBUepjZU6" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />


            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/98c3da682649a70a01bff1b81c7e7df9/860fd96aeb2f9fa8-a8/s400x600/191a4dec1ecdc127ca5308fbaf316da26a611b66.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/c05cb62c2eaf8f4fa80f6a9ea462a32a/0e821f9ccbfea181-6a/s400x600/e951b96b47ab9f0c474bebe1d7cd3627a051e6db.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Another Round</h4>
                        <p className="menor artigo lead">
                            chorar, chorar, chorar, chorar, deus nao existe cara, como pode tamanha desgraça mental, espiritual, entrei animado por esse filme, fui expurgado pro fundo do poço, desiludido, foi de mim arrancada a esperança, em algum momento me surgiu forças pra escalar pra fora desse buraco, meu deus, que vida boa, minha vida é maravilhosa, QUE VIDA...
                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={5} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/4nfpbsiYbB0TVBOeJ7X7Z0?utm_source=generator" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />


            {/* <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/85fc53e9490e9d626d7a04ba5c10c72a/tumblr_pk0gomHtXg1qixy31o4_540.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/79d556f03ddebb4f857f7d1376149551/tumblr_pq80wjTc9q1rgmfmpo1_400.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">The Handmaiden</h4>
                        <p className="menor artigo lead">A beleza do cinema sul coreano é essência constante, um sabor visceral, ainda melhor quando deturpado.</p> <p className="menor artigo lead">Atrizes comprometidas em detalhes, a realidade íntima feminina contrastada com a ultrapassada fantasia érotica patriarca repleta de bestialidade.</p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={4.0} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/2rSAp50njmLe9abwD1BSlc" width="260"  frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr /> */}



            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/518c926b5186fb90da2a0fe52d157993/a9d9467101f2ccbf-c3/s400x600/4aa7ae01b2e6f318cce0970bcdc4dc31be9ed7ca.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/0baed4490d5e429e12d0a4cb5fcff411/tumblr_pjw9ft2HJA1rtzvrno1_r1_540.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Lady Vengeance</h4>
                        <p className="menor artigo lead">Lady Vengeance, de Park Chan-wook, uma protagonista feminina, com sua beleza e bondade admirável, planeja sua redenção por meio da vingança. O senso de justiça, pessoal e coletiva, a importância disso em nossas vidas. Mesmo com tamanha convicção, o senso de ódio só nos afasta do que realmente importa. E sabendo disso, cultivamos a dor de nossos arrependimentos... <p className="menor artigo lead">Lady Vengeance é bonito, faz a vingança parecer algo gratificante, simbólico, mas nada disso importa...</p>
                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={4.5} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/4oaT2V6Nh8usfmD3n6hU9r" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/6cda3347020781302aa37ed1831e0327/b9b590a1d830f17d-b3/s400x600/62bbea61464c27bed607f0db6f9f409e26b423c3.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/074c26fc317e34d84d249b02e3cea673/tumblr_p9m2eoEpdc1xso8c3o4_540.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">(500) Days of Summer</h4>
                        <p className="menor artigo lead"> Superestimado pseudo cult</p>

                        <blockquote class="blockquote">
                            <p class="mb-0">𝓣𝓸 𝓭𝓲𝓮 𝓫𝔂 𝔂𝓸𝓾𝓻 𝓼𝓲𝓭𝓮,</p>
                            <p class="mb-0">𝓘𝓼 𝓼𝓾𝓬𝓱 𝓪 𝓱𝓮𝓪𝓿𝓮𝓷𝓵𝔂 𝔀𝓪𝔂 𝓽𝓸 𝓭𝓲𝓮...</p>
                        </blockquote>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={3.4} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/0WQiDwKJclirSYG9v5tayI" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div className="row mx-3 my-5 justify-content-between">
                <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                    <div class="card" >
                        <img class="card-img-top " src="https://64.media.tumblr.com/552a4034ff393120475e3f9f7055e667/dc0a455c3b0095bc-0a/s400x600/79ad65ad1b753be61ff0437182bd88873b50340c.gifv" alt="Uma Imagem" />
                    </div>
                    <div class="card " >
                        <img class="card-img-top " src="https://64.media.tumblr.com/e5407e8b54a8d0b6be27896d4061381b/35141dfa963784fd-ea/s400x600/f8e11942e643646cabc6ca209eba6f99ad16ed37.gifv" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                    <h4 className="mb-2 text-decoration-none no-link text-dark ">La pianiste</h4>
                    <p className="menor artigo lead">
                        Deturped Horny</p>
                </div>
                <div className="col-lg-3 col-12 flex-column ">
                    <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                        <Typography component="legend">Minha Nota:</Typography>
                        <Rating name="read-only" value={4.6} readOnly precision={0.1} size="medium" />
                    </Box>
                    <div className="text-center">
                        <iframe className="spotifySize" src="https://open.spotify.com/embed/track/1TQ2UYCN7nhfj3cfcFC76V" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                </div>
            </div>



            <hr />




            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/5894a5607e3370a311ab005b418c6d44/tumblr_mmkzmiPuRV1sptye8o1_1280.jpg" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/f1544e84daead6f7f5a127456b660eaf/tumblr_ntx8bqAn8e1rrl0mgo3_250.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Irreversible</h4>
                        <p className="menor artigo lead">Irreversível de Gaspar Noé, filme psicodélico francês sob roteiro temporal invertido, de trás para frente. O filme trata a perseguição a um cara após um crime cometido. O crime ? um estupro explicito em plano sequência de dez minutos seguido de extrema violência… </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={3.7} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/26ZzSLUJMmYp5u1hDELEWH" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/295f24755e7694f7437e25630f756b83/tumblr_p4tr62BcTi1wstc5to2_400.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/b7a59bc62df651849a3c2f06035c5fa8/tumblr_o7i8d5H05D1tu6icgo8_250.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/1e75d8e4dfa5afe3fcb73443e4654d80/81b76a5a24ce20bb-f3/s540x810/7d9eb33fdd420a091e7177de4aa1405059e78463.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Oldboy</h4>
                        {/* <p className="menor artigo lead"><span className="menor artigo lead bold">Meu Filme Favorito</span></p> */}
                        <p className="menor artigo lead">Seja uma rocha ou um grão de areia, ambos afundam na água...</p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={5} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/0BknnnoV4woE5pOg6APWxb" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <hr />


            <div className="row mx-3 my-5 justify-content-between">
                <div className="row">
                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/07b83356a95fb1a81d10250b99c05f2c/tumblr_p1s76mBiDt1r0kp4eo1_540.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/71dafab7665f12dcf7a595a5ceaff426/tumblr_p1s76mBiDt1r0kp4eo6_540.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">Black Mirror : Hang The DJ</h4>
                        <p className="menor artigo lead">
                            Hang The DJ é um episódio de Black Mirror bem agradável de se assistir, um romance sem traumas ou gatilhos que forcem algum sentimento muito intenso, é uma áurea de rotina e pequenos detalhes que fundamentam a relação entre os personagens, por isso tão bom. Um casal que passa por experiências com outras pessoas, se recordam um do outro, se comparam e aprendem sobre o que gostam.                        </p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={3.7} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/64D3dzWyj0GpQT1AHx4kbK" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            {/* <div className="row mx-3 my-5 justify-content-between">
                <div className="row">

                    <div className="my-lg-2 my-1 col-lg-3 col-12 text-center">
                        <div class="card" >
                            <img class="card-img-top " src="https://64.media.tumblr.com/1cadb2628276deedb7560ac199669c72/a7db11d8293c54f1-0a/s400x600/539af48c948fc3f9967a6280b9524b6aa0153bcb.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="https://64.media.tumblr.com/8d814f88f5e3073df8a11d9fca4398a7/943fa7355b63aa95-4e/s400x600/00960e73a468a7f86940405e64fd92a6b307edb0.gifv" alt="Uma Imagem" />
                        </div>
                        <div class="card " >
                            <img class="card-img-top " src="                        https://64.media.tumblr.com/9ce563695000356d3db6eb9c3fe7826f/tumblr_oufri4qrPU1sgelweo4_250.gifv" alt="Uma Imagem" />
                        </div>
                    </div>
                    <div className="my-lg-2 col-lg-6 col-12 flex-column text-center text-lg-left">
                        <h4 className="mb-2 text-decoration-none no-link text-dark ">SHAME</h4>
                        <p className="menor artigo lead">
                            cara, eu nao sei oque vem acontecendo com o mundo, é tão fácil satisfazer o absurdo, quando eu era criança, cada relação, amores, amizades eram tão intensos, eu lembro de como me sentia, hoje ? viciado em pornografia pesada, distante das relaçoes reais, evitando conhecer pessoas, porque elas não são capazes de satisfazer o desejo profundamente deturpado, destruido, corrompido, SHAME, SHAME, SHAME...
                        </p>

                    </div>
                    <div className="col-lg-3 col-12 flex-column ">
                        <Box className="text-center my-2    " component="fieldset" mb={5} borderColor="transparent">
                            <Typography component="legend">Minha Nota:</Typography>
                            <Rating name="read-only" value={4.7} readOnly precision={0.1} size="medium" />
                        </Box>
                        <div className="text-center">
                            <iframe className="spotifySize" src="https://open.spotify.com/embed/track/1oKm3ZrRg1abX3bMN8Tq9Q?utm_source=generator" width="260" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <hr /> */}

        </div>

    </Content>