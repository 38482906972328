import React from 'react'
import Content from '../Content'
import './oldboy.css'
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import img1 from '../../assets/img/evangelion1.jpg'
import produtora from '../../assets/img/evangelion-filmadora.png'
import diretor from '../../assets/img/evangelion-diretor.jpg'


export default props =>
    <Content>

        {/* <div class="jumbotron jumbotron-fluid bg-euphoria">
            <div class="container">
                <h1 class="display-4 text-light">Euphoria</h1>
                <p class="lead text-light">A essência rasa da realidade inventada dos jovens 2020, drogas, sexualidade e abusos. Aula da HBO de como ganhar dinheiro...</p>
            </div>
        </div> */}

        <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="d-block w-100" src="https://livrismos.files.wordpress.com/2013/12/clube-luta-e1387276271653.jpg?w=1200&h=400&crop=1" alt="First slide" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row mt-2">



                <div className=" none col-md-6 col-12 d-flex justify-content-start align-items-start mt-md-5 flex-column">
                    <p className="none"><span className="font-weight-bold mr-5">Formato:</span>Filme</p>
                    <p className="none"><span className="font-weight-bold mr-5">Gênero:</span>Realidade</p>
                    <p className="none font-italic"><span className="font-weight-bold mr-5">Roteiro Por:</span>Chuck Palahniuk</p>
                    <p className="none"><span className="font-weight-bold mr-5">Pais:</span>Estados Unidos</p>

                </div>

                <div className="col-md-3 col-6 mb-4">
                    <p className="lead text-center legend">Diretor</p>
                    <p className="lead text-center text-primary legend">David Fincher</p>

                    <div class="card test" >
                        <img class="card-img-top" src="https://cdn.vox-cdn.com/thumbor/lTjQY97kU8Rq0Rr2bKRnSr5PXJE=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22031337/1201364271.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-3 col-6 mb-4">
                    <p className="lead text-center legend">Produtora</p>
                    <p className="lead text-center text-primary legend">Century FOX</p>

                    <div class="card test" >
                        <img class="card-img-top" src="https://exame.com/wp-content/uploads/2020/01/disney-fox.jpg?quality" alt="Uma Imagem" />
                    </div>
                </div>

            </div>

            <hr />

            {/* <div className="row">
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://lh3.googleusercontent.com/proxy/MRdIx0AFj7P1bckOzVeUsuIOS-ZxJeTHcST76i1xzzEsRKvM1JmKSBZum5UtC5CBIDhlZTtSVDcvMXnY7Xqrl2M3Dtju5vsX07RTTfu7u8gWgom8FXNaj_TqJI7lcRvp94aPtFUEsic-cwFCB_OCXlLet978Jgm1G9oH" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://dotandline.net/wp-content/uploads/2019/06/Shinjiprofil-1024x642.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://i.pinimg.com/originals/f4/21/55/f421556eb56996e04295fc07133d0f72.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://upload.wikimedia.org/wikipedia/en/2/2f/Asukka13.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/a/a2/OP_C015_ritsuko.jpg/260px-OP_C015_ritsuko.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/9/9b/08_C071_kaji.jpg/260px-08_C071_kaji.jpg" alt="Uma Imagem" />
                    </div>
                </div>
            </div> */}


            <div className="row mt-4">

                <div className="col-12">
                    <h2 className="display-3 text-center">OldBoy</h2>

                </div>

                <div className="col12 col-md-12 mt-2">
                    <div class="embed-responsive embed-responsive-16by9">
                    <iframe src="https://www.youtube.com/embed/Wf0o6hBjPNg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>


               
             </div>
        </div>


    </Content>
