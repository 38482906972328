import React from 'react'
import Content from '../Content'
import './euphoria.css'
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import img1 from '../../assets/img/evangelion1.jpg'
import produtora from '../../assets/img/evangelion-filmadora.png'
import diretor from '../../assets/img/evangelion-diretor.jpg'


export default props =>
    <Content>

        {/* <div class="jumbotron jumbotron-fluid bg-euphoria">
            <div class="container">
                <h1 class="display-4 text-light">Euphoria</h1>
                <p class="lead text-light">A essência rasa da realidade inventada dos jovens 2020, drogas, sexualidade e abusos. Aula da HBO de como ganhar dinheiro...</p>
            </div>
        </div> */}

        <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="d-block w-100" src={img1} alt="First slide" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row mt-2">



                <div className=" none col-md-6 col-12 d-flex justify-content-start align-items-start mt-md-5 flex-column">
                    <p className="none"><span className="font-weight-bold mr-5">Formato:</span>Série</p>
                    <p className="none"><span className="font-weight-bold mr-5">Gênero:</span>Ação Drama</p>
                    <p className="none font-italic"><span className="font-weight-bold mr-5">Escrito Por:</span>Yoshiyuki Sadamoto</p>
                    <p className="none"><span className="font-weight-bold mr-5">Pais:</span>Japão</p>

                </div>

                <div className="col-md-3 col-6 mb-4">
                    <p className="lead text-center legend">Diretor</p>
                    <p className="lead text-center text-primary legend">Hideaki Anno</p>

                    <div class="card test" >
                        <img class="card-img-top" src={diretor} alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-3 col-6 mb-4">
                    <p className="lead text-center legend">Produtora</p>
                    <p className="lead text-center text-primary legend">Tatsunoko</p>

                    <div class="card test" >
                        <img class="card-img-top" src={produtora} alt="Uma Imagem" />
                    </div>
                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://i.pinimg.com/originals/4f/f1/63/4ff16334eadac77f1585d7eff80f13eb.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://dotandline.net/wp-content/uploads/2019/06/Shinjiprofil-1024x642.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://i.pinimg.com/originals/f4/21/55/f421556eb56996e04295fc07133d0f72.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://upload.wikimedia.org/wikipedia/en/2/2f/Asukka13.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/a/a2/OP_C015_ritsuko.jpg/260px-OP_C015_ritsuko.jpg" alt="Uma Imagem" />
                    </div>
                </div>
                <div className="col-md-2 col-4 mt-2 text-center">
                    <div class="card test" >
                        <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/9/9b/08_C071_kaji.jpg/260px-08_C071_kaji.jpg" alt="Uma Imagem" />
                    </div>
                </div>
            </div>

            <hr />

            <div className="row mt-4">

                <div className="col-12">
                    <h2 className="display-3 text-center">Neo Genesis Evangelion</h2>

                </div>

                <div className="col-12 col-md-9 mt-2">
                    <p className="artigo text-justify">Bom, Evangelion... eu não assisto muito anime pra falar a verdade, mas admiro muito as animações japonesas, principalmente as antigas, nessa pegada old school, elas meio que não se importam com questões muito morais, trasmitem uma história muito autêntica da visão dos roteristas além de claro, a qualidade gráfica absurda.</p>
                </div>
                <div className="col-12 col-md-3 mt-2">
                    <img class="card-img-top" src="https://i.imgur.com/dwq4itB.gif" alt="Uma Imagem" />

                </div>



                <div className="col-12 mt-2">
                    <p className="artigo text-justify">Neo Genesis Evangelion é um seriado de 26 espisódios, numa terra pós apocaliptca, onde a humanidade luta contra invasores divinos, os anjos, uma represetação extremamente criativa em suas aparências e poderes, que foje absurdamente do clichê ocidental. Para combate-los, as novas autoridades, que nessa situação deixa de ser os governos e passa ser as instituções Nerv, desenvolve maquinas com materiais dos anjos, gigantes e poderosas, mas que só podem ser pilotadas por crianças, oque não é muito explicado na série, mas da a entender que seja pelos seus corações mais puros e inocentes, que contrastam com o conhecimento sexual, existencial e questionador de espirito. Quando essas crianças entram em contato com as máquinas, que são chamadas de EVAs, ou Evangelions, ocorre uma sincronização neural, conectando sentimentos, movimentos e pensamentos entre os dois. Assim, quando a terra é invadida por um novo anjo, os EVAs são liberados e lutam pau a pau com eles, o negócio é animall...</p>
                </div>

                <div className="col-12 mt-2 text-center">
                    <img class="card-img-top" src="https://64.media.tumblr.com/bfee18b24bedb187b6f4c0705033628d/tumblr_n2rah7MEkf1rkeknyo1_500.gif" alt="Uma Imagem" />
                </div>

                <div className="col-12 mt-2">
                    <p className="artigo text-justify">O Protagonista é uma criança de 14 anos, Shinji Ikari, filho do chefe da NERV, ele não gosta de seu pai, porque quando bebe, foi abandonado por ele depois que sua mãe morre em um acidente num teste de EVA por ele feito. Recebendo a intimação por ser a "segunda criança", detém agora de uma obrigação, pilotar o EVA O1, mesmo sem querer, por não ter escolha acaba aceitando a proposta, mudando de escola e passando a morar com uma das chefes da Nerv, Misato Katsuragi. Em seu novo ambiente, passa a enfrentar diariamente um questionamento espiritual sobre seus deveres, de fato não quer pilotar um EVA, sente que isso atrapalha as pessoas, as machuca. Ikari tem a necessidade de poder ser algo bom para as pessoas que ama, justamente pela rejeição do pai, não tem nenhum tipo de referência do que é amar, odiar ou ter prazer, assim, soa como um personagem sem confiança, com medo e constantemente necessitado de conselhos e apoio. Durante a trama, acompanhamos seu desenvolvimento, suas vergonhas, traumas e recentimentos. Mudando com o tempo, sua postura perante o mundo que existe, sendo pressionado e pressionando a si mesmo, para aceitar as coisas como elas são.</p>
                </div>

                <div className="col-12 mt-2 text-center">
                    <img class="card-img-top" src="https://pa1.narvii.com/6057/8e3673158be26176c021b9434155b2d0c3b2d267_hq.gif" alt="Uma Imagem" />
                </div>

                <div className="col-12 col-md-8 mt-2">
                    <p className="artigo text-justify">Misato Katsuragi é minha personagem preferida, extremamente apaixonante, demonstra um ar de humor, liderança e sensualidade magníficas, creio que seja uma das personas fictícias que mais me cativou em tempos, quando Ikari se muda para a Nerv, Misato o adota como aprendiz, sendo assim sua Guardiã. Shinji Ikari, passa a se adaptar ao apartamento de Misato, que é onde ficará agora. Neo Genesis Evangelion é um maestro em tornar cenas mundanas em algo bonito e engraçado, numa abordagem muito sutil e agradável. Um amanhacer quando Misato Katsuragi sai de seu quarto cansada e descabelada, e abre uma boa cervejinha gelada, dando seu famoso grito de euforia..</p>
                </div>
                <div className="col-12 col-md-4 mt-2 text-center">
                    <p className="artigo text-center">Que cena meus amigos, que cena..</p>
                    <iframe className="text-center" width="350" height="220" src="https://www.youtube.com/embed/ygFOJKiftW0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>


                <div className="col-12 mt-2">
                    <p className="artigo text-justify">A naturalidade que Evangelion tras para as mais comuns naturalidades reais é sensacional, desde um jantar com comidas instantâneas, até a divisão de espaço entre calcinhas e cuecas no banheiro, todo dia a dia que serve de background para trama principal, cativa, e traz pras cenas mais importantes, um sentimento muito mais intenso, nos apaixonamos por todos os personagens, e o tempo todo somos surpreendidos por alguma coisa, que de fato poderia acontecer na vida real, e acontece na verdade, mas que na maioria das artes pra televisão são romantizados, inves de humanizados.</p>
                </div>


                <div className="col-12 mt-2 text-center">
                    <img class="card-img-top" src="https://pa1.narvii.com/6135/7b5039085268ce031ad5bc6467e21710045eef1d_hq.gif" alt="Uma Imagem" />
                </div>

                <div className="col-12 my-3">
                    <blockquote class="blockquote text-center">
                        <p class="mb-0">Aqui, mesmo que tenhamos anjos invadindo e maquinas pulando prédios, os sentimentos são humanos, sejam eles tabus, questionáveis ou repugnantes... </p>
                    </blockquote>
                </div>

                <div className="col-12 mt-2">
                    <p className="artigo text-justify">Durante a trama, também acompanhamos a história de cada peronagem, os motivos de serem como são e oque passaram pra estar ali, Misato Katsuragi, mesmo sendo uma maravilhosa lider de operação, amiga e oficial, é exposta sob fragilidade, indicando seus medos, seus amores, prazeres e relacionamentos.
Encaramos cíumes descontrolados, mortes, frustrações e arrependimentos, carregamos uma angústia de querer que as coisas deêm certo, mas reconhecendo que o mundo não é sempre justo.</p>
                </div>

                <div className="col-12 mt-2 text-center">
                    <img class="card-img-top" src="https://i.pinimg.com/originals/c7/6d/fb/c76dfbd878201360830ef4e0fc0ed1b8.gif" alt="Uma Imagem" />
                </div>

                <hr />

                <div className="col-12 mt-3">
                    <p className="artigo text-justify">Nesse novo ambiente também é levantado o relacionamento entre Shinji e Asuka, cada um com personalidades diferentes, carregados de traumas e brigando sempre entre si, trabalhando juntos e se descobrindo sozinhos. Asuka quer ser uma mulher, não quer ser uma criança, possui um temperamento forte e uma confiança estupenda, que mesmo desgastando com o tempo, faz dela uma nova protagonista.</p>
                </div>

                <div className="col-12 mt-2">
                    <p className="artigo text-justify">Neo genesis Evangelion então, é uma série humanizada, sci-fi que reune assuntos taboos numa abordagem asiatica, de fato acredito e apoio o culto a ela, uma arte de quase 30 anos, que ainda detém assuntos tão atuais e discutidos.
A mistura religiosa ao ambito geek, trouxe uma postura inovadora em como vemos as condiçoes de mundo, ao mesmo tempo que temos os anjos, brigamos contra nós mesmos, ficamos felizes, tristes, com ódio, mas no fim tudo se resume em cada um de nós, ou a sí mesmo pra ser mais exato. Porque de fato, a unica coisa que existe pra mim sou eu mesmo e oque eu vejo através de mim, ser Deus, sentir, amar, querer, desejar. Apenas eu tenho oque tenho e sou oque sou, mesmo com todo mundo a minha volta, toda sas pessoas e ações que podem me afetar, continuamos sozinhos numa luta incessável por respostas.</p>
                </div>

                <div className="col-12 mt-2 text-center">
                    <img class="card-img-top" src="https://alimenteocerebro.com/wp-content/uploads/2020/05/The_end_of-_Eva.gif" alt="Uma Imagem" />
                </div>




                <div className="row my-4">
                    <div className="col-12 col-md-6 d-flex align-items-center">
                        <blockquote class="blockquote mt-2">
                            <p class="mb-0 text-center">"Mesmo que tenhámos a impressão que dividimos uma concepção com alguém, no fim resta apenas nós mesmos."</p>
                            <p class="mb-0 text-center">"Um beijo de adulto Shinji Kun, quando você voltar continuaremos..."</p>
                            <footer class="blockquote-footer ml-5 text-center">Misato Katsuragi<cite className="ml-2" title="Título da fonte">End Of Evangelion</cite></footer>
                        </blockquote>
                    </div>

                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                        <img class=" img-fluid" src="https://i.pinimg.com/originals/83/e9/fc/83e9fc5351debf2aab0a038e54483811.gif" alt="" />
                    </div>
                </div>

                <hr />


                <hr />

                <div className="col-12 mt-2">
                    <p className="artigo text-center text-primary">Artigo feito por mim, baseado nos 24/26 episódios, mais o filme The End Of Evangelion, que é o final alternativo da série, sendo substituto dos ultimos 2 espisódios originais.</p>
                </div>

                <hr />

                <div className="col-12 my-3">
                    <blockquote class="blockquote text-center">
                        <p class="mb-0">Thanks Misato Katsuragi.</p>
                    </blockquote>
                </div>

                <div className="col-12 mt-2 text-center">
                    <img class="card-img-top" src="https://i.imgur.com/zmoItlq.gif" alt="Uma Imagem" />
                </div>


            </div>
            <hr />

            <div className="row text-center">
                <div className="col-md-6 col-12 mt-3 text-center">
                    <h4 className="">Trilha da Série</h4>
                    <iframe src="https://open.spotify.com/embed/album/04lUd54qAo5kWaUcAxbtgA" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>

                <div className="col-md-6 col-12 mt-3">
                    <h4 className="">Minha trilha favorita da Série</h4>
                    <iframe src="https://open.spotify.com/embed/track/0u9EI8zmr2QMuJLMiQFUIn" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
            </div>
            <hr />
        </div>


    </Content>
