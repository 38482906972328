import React from 'react'
import Content from '../Content'
import './wrapped.css'

import artistas from '../../assets/img/wrapped-2022-2.JPG'
import tempo from '../../assets/img/wrapped-2022.JPG'
import resumo from '../../assets/img/wrapped-2022-03.JPG'


export default props =>
    <Content>
        <div class="jumbotron jumbotron-fluid bg-watched" ></div>
        <div className="col-12 my-2">
        </div>


        <div className="container">
            <div className="row">
                <div className="col-12">
                    <a href="https://open.spotify.com/user/niibiru" target="_blank">
                        <div className="text-center">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/2048px-Spotify_logo_without_text.svg.png" alt="" height="60" />
                            <h5 className="mb-2 text-decoration-none no-link text-success ">Visit my Spotify</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <hr />


        <div className="container">


            <div className="row">
                <div className="col-6">
                    <h5 className="text-left menor">Restrospectiva Exposed</h5>
                </div>
                <div className="col-6">
                    <h5 className="text-right menor">2022</h5>
                </div>
            </div>

            <div className="row text-center mt-2">
                <div className="col-md-4 col-12 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src={resumo} alt="Card image cap"></img>
                    </div>
                </div>
                <div className="col-md-4 col-12 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src={artistas} alt="Card image cap"></img>
                    </div>
                </div>
                <div className="col-md-4 col-12 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src={tempo} alt="Card image cap"></img>
                    </div>
                </div>
            </div>


            <hr className='mt-5' />
            <div class="row">
                <div className="col-12">

                    <h5 className="text-center menor wrapped-text">Mais ouvidos do ano 2022</h5>
                </div>
                <div class="col-md-2 offset-md-5 col-4 offset-4">
                    <img class="card-img-top" src="https://64.media.tumblr.com/2bf6c27599d871c751533eb58a3662d8/95a1cef217a25ef5-70/s540x810/3a4f998505c427254b04a8701c65bfad7a64fd7f.gifv" alt="Card image cap"></img>
                </div>

                <div className="col-12">
                    <h5 className="text-center menor mt-1 pop-art">Mitski</h5>
                </div>
            </div>
            <hr />
            <iframe src="https://open.spotify.com/embed/playlist/3biXl4kQUiZ1ZseQkyivdF" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>


            <hr />

            <div className="row">
                <div className="col-6">
                    <h5 className="text-left menor">Restrospectiva Exposed</h5>
                </div>
                <div className="col-6">
                    <h5 className="text-right menor">2021</h5>
                </div>
            </div>

            <div className="row text-center mt-2">
                <div className="col-md-4 col-12 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://pbs.twimg.com/media/FFiHKqOXwAkLFrB?format=jpg&name=large" alt="Card image cap"></img>
                    </div>
                </div>
                <div className="col-md-4 col-12 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://pbs.twimg.com/media/FFiIc4-XEAAI063?format=jpg&name=large" alt="Card image cap"></img>
                    </div>
                </div>
                <div className="col-md-4 col-12 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://pbs.twimg.com/media/FFiIcK5XwAYcbat?format=jpg&name=large" alt="Card image cap"></img>
                    </div>
                </div>
            </div>

        </div>
        <div className="container">

            <hr className='mt-5' />
            <div class="row">
                <div className="col-12">

                    <h5 className="text-center menor wrapped-text">Mais ouvidos do ano 2021</h5>
                </div>
                <div class="col-md-2 offset-md-5 col-4 offset-4">
                    <img class="card-img-top" src="https://64.media.tumblr.com/7cd48e17f9303ffdcf7ca45486bedab2/1b661ab70b1ab2c7-03/s540x810/b1cf403a2ec4cd71c5476f1d02a3126b17b7af9a.gif" alt="Card image cap"></img>
                </div>

                <div className="col-12">
                    <h5 className="text-center menor mt-1 pop-art">Mitski</h5>
                </div>
            </div>
            <hr />
            <iframe src="https://open.spotify.com/embed/playlist/7HqLT7iiG1rn3r5BshPTNa" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>






            {/* <p className="menor text-justify">Em 2018 estudando para faculdade, fazendo outro curso técnico, abracei de volta a música indie, misturei bastante coisa e fiz o erro de emprestar o Spotify para minha irma, entao vira e mexe aparecia algum Rap ou Funk no On Repeat, mas tudo bem kkk foi o ano da Marina and The Diamonds, nao ouvia aquilo porque era deprimente, ouvia porque era legal lembrar da minha depressão sem estar deprimente hehe Neste ano houve muita Ophelia repetindo por horas a fio, The Lumineers Calmin e tranquilin </p> */}




            <hr className='mt-5' />
            <div class="row">
                <div className="col-12">

                    <h5 className="text-center menor wrapped-text">Mais ouvidos do ano 2020</h5>
                </div>
                <div class="col-md-2 offset-md-5 col-4 offset-4">
                    <img class="card-img-top" src="https://68.media.tumblr.com/79a0f0eff6f6f539a1fd083844d5c2a9/tumblr_o1le13708y1s114ugo1_500.gif" alt="Card image cap"></img>
                </div>

                <div className="col-12">
                    <h5 className="text-center menor mt-1 pop-art">Crystal Castles</h5>
                </div>
            </div>

            <hr />
            <iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1EMgeTSPzM8kjG?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>


            {/* <p className="menor text-justify">Em 2019 ganhei uma bolsa pelo Prouni na PUC-Campinas, então entrei em choque de realidade, fucking consciência de classe ? uheuhe todo meu undergroundorismo anarquista juvenil aflorou, eu deveria ter estudado mais para entrar numa federal, lá pelo menos teria boyzinhos sustentados pelos pais inteligentes, em universidade privada de polo tecnológico no turno noturno você encontra jovens que nunca precisaram resolver um problema na vida e trabalham meio período na empresa do pai. Nos cursos integrais, tem uma rapaziada do bem, mas a Noite ?..
Eu tinha duas opções, tentar fingir ser oque eu não era ou assumir a postura fucking fisting your mom, nesse período passei a ouvir mais punk e underground music, coisa de bandas que nem tem no spotify. Então me aconteceu uma coisa engraçada que acho que seja necessário até um novo parágrafo…
No começo do curso houve um projeto de Hackathon de tecnologia da IBM na universidade PUC-CAMPINAS me inscrevi para participar, já tinha trabalhado na IBM anteriormente quem sabe ganhar uma grana extra denovo, as salas disponibilizadas ficavam no prédio de Arquitetura e Urbanismo, posso estar generalizando mas vou dizer oque vi entre os semestres que lá estudavam na época, 80% mulheres entre 20 a 25 anos brancas de classe alta 15% homens homosexuais que usavam gola alta no calor ‘-’, 5% hetero top hb20. Até aí tudo bem, nenhum deles estava inscrito no Hackathon, só usávamos o prédio, porém em um intervalo… Um cara dessa faculdade perguntou sobre oque eu estava ouvindo, era Crystal Castles, ele, alucinado na imaginação de ser filho do John Lennon, tinha uma banda de música eletrônica, fiquei um tempo na sala deles vendo os vídeos da banda, quando ele colocou uma música para tocar no projetor enquanto suas colegas de classe entravam, o titulo do video era “World Ruined a Whole Generation of Women” do negativexp, instantaneamente começou-se uma discussão onde esse cara dava risada e eu ali presente apenas fiquei olhando todos eles, quando então a Loirona apontou para mim e disse “É esse tipo de gente que gosta desse tipo de música” eu sem entender perguntei, “Que tipo de gente?” e ela perguntou tentando amenizar "Você é do Prouni né?”, a rapaziada ficou meio em silêncio, eu também, me arrependo, eu já estava acostumado com isso mas não sabia oque responder, eu acabei falando “Sabe esse vídeo, eu vou meter uma legenda nele e vou compartilhar, você é igualzinha a ele..” Eu não achava ela igual ao que a música dizia, eu nem conhecia a música, depois que conheci achei muito ruim, mas eu queria fazer qualquer coisa pra irritar, no mesmo dia, pelo celular, traduzi tudo errado, e postei
</p> */}


            {/* <p className="menor text-justify">Sabe qual o mais foda ? A maioria da rapaziada ali, sabia que dizer isso era errado, mas sabe oque eu sentia ? Que todos achavam o mesmo, essa inferioridade que tentam jogar pra cima de mim, nos dias seguintes, vindo conversar comigo, querendo conhecer mais sobre a minha vida, acho que eles esperavam que eu ia tentar ser amigo deles, fodam-se eles, fodam-se todo o discurso de pessoas que nunca passaram perrengue na vida acharem que sabem algo sobre alguém, não sabem kkkkk. Desde então, isso não só canalizou uma preferência anti comercial que eu já tinha, como mudou muito minhas opiniões políticas, sociais e econômicas, para todo tipo de coisa que eu refletia, eu encarava com outros olhos. Durante muito tempo fui de extrema esquerda, crítico do capitalismo, feminista.. Quando na real, muito desses tópicos hoje são dominados pela elite bancada de aristocrata meritocrático paneleiro, enquanto eu tenho que me foder encarando a realidade, eles estao ocupados falando sobre cultura do estupro, sexisismo, suicidio, virando influencers digitais do instagram, quem batalha contra cultura do estupro é minha mãe que volta andando todo dia do trabalho, é as assistêntes sociais que salvam crianças em situações de risco, essa conversa de bate papo podcast altas horas da globo, com top 5 influencers lancando a therads no twitter, que porcaria do caralho, superficial, desinformação, quem precisa de ajuda, quem tem importância mesmo, ta muito, muito longe dos holofotes… Não que eu compactue com pautas da Direita, eu sou apenas realista. Como eu, um indivíduo pobre, posso ficar rico, melhorar de vida, ajudar meus semelhantes, tanto com Bolsonaro, quanto com Lula no poder ? */}
            {/* </p> */}

            {/* <p className="menor text-justify">Muito texto e fugindo do que queria falar, o site é meu entao fodace, Não confie em Nós Crystal Castles, não tem letra, só som e uma filosofia que você mesmo decide, a cocaína não faz bem para você.</p> */}


            {/* <p className="menor text-justify">Após o caos veio a redenção, não vale a pena gastar muita energia na luta desorganizada contra o capitalismo, desta forma só abrimos brecha para o aproveitamento das pautas para fins comerciais, ou todos nós nos juntamos organizadamente para destruir as instituições privadas opressoras e instituir a anarquia supremacista parda, ou sejamos realistas, junta a graninha faz uns investimentos e tenta viver com dignidade.. */}
            {/* </p> */}





            <hr className='mt-5' />
            <div class="row">
                <div className="col-12">

                    <h5 className="text-center menor wrapped-text">Mais ouvidos do ano 2019</h5>
                </div>
                <div class="col-md-2 offset-md-5 col-4 offset-4">
                    <img class="card-img-top" src="https://64.media.tumblr.com/93da2e84e2af8d240f92bc4dc08b5a3b/512ed02ed777cdfe-9f/s400x600/28543bdbf70be1a8a4fe7a7c39e09deadb0aa6d5.gifv" alt="Card image cap"></img>
                </div>

                <div className="col-12">
                    <h5 className="text-center menor mt-1 pop-art">AWOLNATION</h5>
                </div>
            </div>
            <hr />

            <iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1EtmOSPTqB7fEp?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>

            <hr className='mt-5' />
            <div class="row">
                <div className="col-12">

                    <h5 className="text-center menor wrapped-text">Mais ouvidos do ano 2018</h5>
                </div>
                <div class="col-md-2 offset-md-5 col-4 offset-4">
                    <img class="card-img-top" src="https://64.media.tumblr.com/de3d480865a356c4c9204a57096392e7/478282957d469b57-6c/s500x750/3f86dadbf447b5ecf4a2d0cec05ef602174b8461.gifv" alt="Card image cap"></img>
                </div>

                <div className="col-12">
                    <h5 className="text-center menor mt-1 pop-art">MARINA</h5>
                </div>
            </div>
            <hr />

            <iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1Ejy3pIcKr2eZV?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>



            <hr className='mt-5' />
            <div class="row">
                <div className="col-12">

                    <h5 className="text-center menor wrapped-text">Mais ouvidos do ano 2017</h5>
                </div>
                <div class="col-md-2 offset-md-5 col-4 offset-4">
                    <img class="card-img-top" src="https://64.media.tumblr.com/e1724e7e67b2495a5b5082a997672c77/5def68417cd6e397-89/s400x600/86b1b2955ee357c6c2c70ba152cf4118520d9ccb.gifv" alt="Card image cap"></img>
                </div>

                <div className="col-12">
                    <h5 className="text-center menor mt-1 pop-art">Tove Lo</h5>
                </div>
            </div>
            <hr />
            {/* <p className="menor text-justify">De 2016 para 2017 ainda no ensino médio eu fui ficando um pouco mais do contra com música mainstream, pow mesmo não curtindo esse nicho de famosos, Malibu da Miley Cyrus, the classic Bad Romance da Lady Gaga e Tove Lo Stay High .-.  música do caralho, foi um ano meio fraco pra mim então acabei ouvindo uns clássicos nacionais também, tava saindo da fase bad dos 15 anos então Charlie Brown Jr na mente né huh uh não sei como não virei maconheiro :D. Muita música folk também..</p> */}
            <iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1E9TepXxQ8Xuzu?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>



            <hr className='mt-5' />
            <div class="row">
                <div className="col-12">

                    <h5 className="text-center menor wrapped-text">Mais ouvidos do ano 2016</h5>
                </div>
                <div class="col-md-2 offset-md-5 col-4 offset-4">
                    <img class="card-img-top" src="https://64.media.tumblr.com/28fda76b196a337fdf8f1dc358e12c09/tumblr_p0hkzpYAW51v6n2qao5_500.gifv" alt="Card image cap"></img>
                </div>

                <div className="col-12">
                    <h5 className="text-center menor mt-1 classic-vintage">The Housemartins</h5>
                </div>
            </div>
            <hr />


            {/* <p className="menor text-justify">Eu ouço muita música, talvez porque meu estilo de vida atual favorece isso, longos períodos sentado escrevendo código em silêncio, acho que também por causa disso prefiro músicas com mais melodias do que letras, como se fossem trilhas sonoras. Pela infância tive muita influência do meu pai pelo rock, Elvis, The Animals e meu preferido Creedence, depois durante a adolescência entrei numa fase meio sad das ideias, e comecei a ouvir muita música “Indie” que na época era independente mesmo, isso em 2014/2015 era a insanidade do emo adolecente, MixTV, coisa boa, hoje nas redes sociais viraram trilha sonora pra dancinha, mas nao vou diminuir essa geração, talvez se fosse eu faria o mesmo né.. Nessa época meu dia era Lana Del Rey, Rock Clássico e uns Milky Chance depressivo…</p> */}
            <iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1CyZ9SkBaXoPg7?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>



            {/* <p className="menor text-justify">Em 2020 ainda atingido pelo ar da graça do no hay studios, conheci bandas russas e francesas, nesses lugares, principalmente na rússia, não há muitos estímulos comerciais para se fazer arte, você não vai ficar milionario como acontece no sertanejo universitário brasileiro, funk ou rap pós 2020, o único e exclusivo propósito é se expressar, autenticamente, genuinamente, visceralmente. Para mim ? Os adjetivos obrigatórios para algo ser considerado arte…</p> */}


            {/* <p className="menor text-justify">Não que eu menospreze as pessoas que ouvem Funk, Sertanejo Universitário, Trap, etc.. Só acho que se essas pessoas não se interessam em como esses conteúdos são produzidos, elas não estão muito interessadas em qualquer conteúdo artístico, estão simplesmente ouvindo sem pretensões algo momentâneo sem nenhum vínculo com seus sentimentos, passageiro, tendência, moda… Isso não me incomoda, poxa as pessoas precisam se divertir, eu só sinto que cada vez mais não há interesse em nada genuíno, só moda, só comercio. */}
            {/* </p> */}

            {/* <p className="menor text-justify">Ta ai um video que eu adoro, sabe oque isso significa ? Porra nenhuma, eu nao sou evoluido por gostar de tal coisa, fulano não é inferior por dançar tal coisa…</p> */}

            {/* <p className="menor text-justify">2021, easy, nao tem muita coisa aqui nao, tem russo, francês, asiatico, Washing Machine Heart, acho que aqui chegamos no ponto que pode ser a solução para evitar o nascimento do pretexto Unabomber Manifest, uma namorada, talvez eu precise de uma, talvez isso seja a dissolução de toda minha perspectiva existencialista. </p> */}

        </div>

        <hr />

    </Content>