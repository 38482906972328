import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'






export default props =>
    <nav className="navbar">
        <ul class="nav flex-lg-row align-self-md-center flex-nowrap mx-0 flex-md-wrap ">
            <li class="nav-item">
                <Link className="" to="/"><a class="nav-link">Home</a></Link>
            </li>

            {/* <li class="nav-item">
                <Link className="" to="/me" ><a class="nav-link" >Sobre Mim</a></Link>
            </li> */}

            {/* <li class="nav-item">
                <Link className="" to="/movies"><a class="nav-link">Crítica</a></Link>
            </li> */}
            <li class="nav-item">
                <Link className="" to="/watched"><a class="nav-link">Filmes</a></Link>
            </li>
            {/* <li class="nav-item">
                <Link className="" to="/politic"><a class="nav-link">Política</a></Link>
            </li>
            <li class="nav-item">
                <Link className="" to="/me" ><a class="nav-link" >Histórias</a></Link>
            </li> */}

            <li class="nav-item">
                <Link className="" to="/music" ><a class="nav-link" >Música</a></Link>
            </li>
            {/* <li class="nav-item">
                <Link className="" to="/history" ><a class="nav-link" >Contos</a></Link>
            </li> */}

            <li class="nav-item">
                <Link className="" to="/random" ><a class="nav-link" >Random</a></Link>
            </li>



            <li class="nav-item">
                <Link className="" to="/games" ><a class="nav-link" >Jogos</a></Link>
            </li>

            <li class="nav-item mobile">
                <Link className="" to="/espolio" ><a class="nav-link" >Espólio</a></Link>
            </li>


        </ul>
    </nav>