import React from 'react'
import './Content.css'

export default props =>

    <React.Fragment>

        <main className="content">
            {props.children}
        </main>


    </React.Fragment>
