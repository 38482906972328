import React from 'react'
import Content from '../Content'
import './writte-me.css'


export default props =>
    <Content>

        {/* <div class="jumbotron jumbotron-fluid bg-euphoria">
            <div class="container">
                <h1 class="display-4 text-light">Euphoria</h1>
                <p class="lead text-light">A essência rasa da realidade inventada dos jovens 2020, drogas, sexualidade e abusos. Aula da HBO de como ganhar dinheiro...</p>
            </div>
        </div> */}

        {/* <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    //<img class="d-block w-100" src="https://i.pinimg.com/originals/49/d5/f0/49d5f0e431eb140e9239136ac2fcf01f.png" alt="First slide" />
                </div>
            </div>
        </div> */}

    
    <div className="container">
        <p className="h6 my-4 text-justify artigo">


        A maioria das pessoas acredita que são melhores que a média, sentem que estão ligeiramente mais corretas e às vezes até mais bonitas que as outras, e isso é comum. Arrisco dizer que todo mundo sente isso às vezes, claro que temos altos e baixos, mas no geral, dividimos as mesmas qualidades e os mesmos defeitos, em infinitas combinações e intensidades diferentes, mas todos. Acho que isso é importante pra nossa personalidade, garante que tenhamos fé em nossas características, definindo assim, nossas paixões e sonhos, colocando ao mundo uma variação da vida, mas principalmente aumentando a diversidade da existência. O extremo egocentrismo é prejudicial; Nos negamos a levar em consideração qualquer coisa diferente de nós, mesmo assim, de certa forma, é cravado no mundo quem você é. Já a abnegação de si próprio, em desdém sobre seus sentimentos, além de triste, é literalmente inútil. Então, porque não aceitar a si próprio?  a ponto de ter forças para acreditar no valor de suas próprias expressões. Isto é, em todos os aspectos, produtivo.


        </p>

        <p className="h6 my-4 text-justify artigo">


        Não estou falando da aceitação cômoda, e sim da aceitação estimulante, aquela que promove melhorias e aspirações. Se trata de tesão, entusiasmo e vontade. O assopro da evolução, conseguir, adaptar, conseguir e adaptar... Quando algo bom acontece, o sentimento que pareço ter é de entusiasmo e ansiedade, parte porque sei que depois daquilo haverá mais, então pra mim estes momentos estabelecem um seguinte. Seguinte porque a vida segue. 
Se digo que tenho um sonho e, após sua consagração, eu ali dizer que estarei permanentemente realizado, estarei me iludindo, porque hoje em sã consciência, fora da vida romântica que me roubaria a sanidade, sei que os sentimentos da realização são totalmente temporários, que os sonhos e desejos uma vez cumpridos e saciados são substituídos por novos, melhores, piores ou diferentes.



        </p>

        <p className="h6 my-4 text-justify artigo">


        É de caráter natural, a necessidade de variação. Biologicamente uma das formas de melhoria escolhidas pela evolução foi a variabilidade física/social. Existe uma repulsa natural por entes familiares porque são parecidos demais geneticamente, o que biologicamente falando aumenta a possibilidade de fracasso genético. Ao outro extremo, temos a mesma repulsa por pessoas muito diferentes, talvez porque, nos relacionando com eles, estaríamos em desdém sobre nossas próprias raízes genéticas. 
Que governos, famílias e sociedades que optaram pela eugenia de uma raça ou genealogia fracassaram não há dúvidas.



        </p>

        <p className="h6 my-4 text-justify artigo">


        É bastante fácil pra mim pensar na evolução da crença como pilar da construção social porque há um embate constante da sua influência na vida das pessoas, desde política, relações familiares, escola, arte e tudo o que foge das necessidades fisiológicas básicas, estas que já são definidas por sí só no senso comum provido pelo peso biológico. 


        </p>

        <p className="h6 my-4 text-justify artigo">


        Estas interferências sociais, que não trata o indivíduo apenas como espécie mas também como parte da sociedade, define inúmeras condições, normas, direitos e deveres. A crença define a cultura/política, e por consequência, define parte do indivíduo que a constitui. Porém a crença também é definida por estes indivíduos, então tudo isso me parece um ciclo retroativo de mudanças para se adaptar em ambos os cenários, tanto para se adequar às necessidades do indivíduo como espécie, quanto o indivíduo perante sociedade.


        </p>

        <p className="h6 my-4 text-justify artigo">


        Sabendo de tudo isso, fica claro pra mim a aceitação da mudança como algo prático e vantajoso, que nem sempre vai ser bom, mas o erro é parte da evolução. Existem religiões, movimentos políticos, teorias da economia, tabus sociais e inúmeras outras interpretações, que mudam o tempo todo, é feita revoluções, é feito impeachment, é vivido tendências e crises mundiais, mas só estamos aqui graças a nossa capacidade de se adaptar e claro a nossa querida ciência..


        </p>
        <p className="h6 my-4 text-justify artigo">


        E agora vou dizer uma coisa estranha, ciência e religião partem da mesma coisa, a necessidade de se saber. A diferença entre elas é que uma aceita o fato de que não sabe de nada, por isso aceita mudanças nas suas próprias crenças e métodos, aceita ser totalmente reconstruída através de outra perspectiva e duvida de si mesmo, essa é a ciência. Já a religião define uma perspectiva e se mantém fiel a ela, isso na verdade é um dos princípios de si mesma, não quero generalizar mas por definição se trata disto, o que promove a desinformação, mesmice e monotonia, são características que em minha opinião definem o fracasso…


        </p>
        <p className="h6 my-4 text-justify artigo">


        Claro, existem pessoas religiosas que praticam a dúvida, mesmo dentro de seus limites. Isso é saudável, e de certa forma, a maioria das religiões prezam o amor e bondade, o que também estimula a melhoria social, eu admiro tudo isso. Mas do ponto de vista atual, a maioria esmagadora acabou se decaindo a interpretações religiosas que fundamentam pensamentos políticos, sociais e econômicos, ou seja, deixou de ser uma experiência pessoal e passou a interferir na vida de outras pessoas, que às vezes não seguem aquela crença, isso é perigoso e estamos hoje colhendo esses frutos envenenados… 


        </p>

        <p className="h6 my-4 text-justify artigo">


        Mas em minha realidade pessoal, me esforço em viver pela filosofia da ciência, que não é exata, muito menos confortável. Isso não quer dizer que vivo apenas por coisas absolutas e palpáveis. Não ignoro o “sentir”, o "inexplicável", na verdade são neles que eu mais me dedico pensar, porque sinto, porque quero, como farei. Me auto conhecendo e me permitindo conhecer, tudo o que puder e como puder.


        </p>

        <p className="h6 my-4 text-justify artigo">


        Não se trata de estar certo, se trata de aproveitar as perspectivas que me estão disponíveis conhecer, em pró de alguma evolução social ? Na maioria das vezes não, em 80% dos casos as reflexões são pra dar alguma risada, testar alguma crença íntima mas para principalmente criar diversidade de vida, porque isso sim é necessário e só estamos aqui hoje porque criamos inúmeros vieses.


        </p>

        <p className="h6 my-4 text-justify artigo">


        Em essência eu sinto fome, sinto dor, sinto tristeza e felicidade, sinto ainda mais quando não consigo entender esses sentimentos, quando não posso me perguntar sobre eles, quando não posso duvidar deles. Então assim eu o faço, duvido, questiono e brigo, às vezes só pra encher linguiça numa apresentação da faculdade que não estudei, as vezes para superar o sofrimento de um luto inexplicável, mas sempre para viver..


        </p>
            </div>



    </Content>
