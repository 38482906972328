import React from 'react'
import Content from '../Content'
import './watchmen.css'
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import img1 from '../../assets/img/evangelion1.jpg'
import produtora from '../../assets/img/evangelion-filmadora.png'
import diretor from '../../assets/img/evangelion-diretor.jpg'
export default props =>
<Content>
   {/* 
   <div class="jumbotron jumbotron-fluid bg-euphoria">
      <div class="container">
         <h1 class="display-4 text-light">Euphoria</h1>
         <p class="lead text-light">A essência rasa da realidade inventada dos jovens 2020, drogas, sexualidade e abusos. Aula da HBO de como ganhar dinheiro...</p>
      </div>
   </div>
   */}
   <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
         <div class="carousel-item active">
            <img class="d-block w-100" src="https://i0.wp.com/cloud.estacaonerd.com/wp-content/uploads/2018/08/17174142/WATCHMEN-LINEUP.jpg" alt="First slide" />
         </div>
      </div>
   </div>
   <div className="container">
      <div className="row mt-2">
         <div className=" none col-md-6 col-12 d-flex justify-content-start align-items-start mt-md-5 flex-column">
            <p className="none"><span className="font-weight-bold mr-5">Formato:</span>Filme</p>
            <p className="none"><span className="font-weight-bold mr-5">Gênero:</span>Herois Mature</p>
            <p className="none font-italic"><span className="font-weight-bold mr-5">Escrito por:</span>Alan Moore, Dave Gibbons</p>
            <p className="none"><span className="font-weight-bold mr-5">Pais:</span>Estados Unidos</p>
         </div>
         <div className="col-md-3 col-6 mb-4">
            <p className="lead text-center legend">Diretor</p>
            <p className="lead text-center text-primary legend">Zack Snyder</p>
            <div class="card test" >
               <img class="card-img-top" src="http://t0.gstatic.com/images?q=tbn:ANd9GcQ6DjoRFn9R6Jtl2iDCMTyp5xShvbdQjlwMkMCXApTjDL6Bf7rjoZ3ruAbiBzDb" alt="Uma Imagem" />
            </div>
         </div>
         <div className="col-md-3 col-6 mb-4">
            <p className="lead text-center legend">Produtora</p>
            <p className="lead text-center text-primary legend">Warner</p>
            <div class="card test" >
               <img class="card-img-top" src="https://lh3.googleusercontent.com/proxy/QwBn4CHWbtMjC9LnbDHw37RpjAAXlnr7qjxAh8O8SATmGqKgEvGP6KXrjCMVaohT7CvrlkvvudVPEfxiOWAmDi7dm_2VeMC4t1HHuP5fQaS1BOZzfX-IzLrj" alt="Uma Imagem" />
            </div>
         </div>
      </div>
      <hr />
      {/* 
      <div className="row">
         <div className="col-md-2 col-4 mt-2 text-center">
            <div class="card test" >
               <img class="card-img-top" src="https://lh3.googleusercontent.com/proxy/MRdIx0AFj7P1bckOzVeUsuIOS-ZxJeTHcST76i1xzzEsRKvM1JmKSBZum5UtC5CBIDhlZTtSVDcvMXnY7Xqrl2M3Dtju5vsX07RTTfu7u8gWgom8FXNaj_TqJI7lcRvp94aPtFUEsic-cwFCB_OCXlLet978Jgm1G9oH" alt="Uma Imagem" />
            </div>
         </div>
         <div className="col-md-2 col-4 mt-2 text-center">
            <div class="card test" >
               <img class="card-img-top" src="https://dotandline.net/wp-content/uploads/2019/06/Shinjiprofil-1024x642.jpg" alt="Uma Imagem" />
            </div>
         </div>
         <div className="col-md-2 col-4 mt-2 text-center">
            <div class="card test" >
               <img class="card-img-top" src="https://i.pinimg.com/originals/f4/21/55/f421556eb56996e04295fc07133d0f72.jpg" alt="Uma Imagem" />
            </div>
         </div>
         <div className="col-md-2 col-4 mt-2 text-center">
            <div class="card test" >
               <img class="card-img-top" src="https://upload.wikimedia.org/wikipedia/en/2/2f/Asukka13.jpg" alt="Uma Imagem" />
            </div>
         </div>
         <div className="col-md-2 col-4 mt-2 text-center">
            <div class="card test" >
               <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/a/a2/OP_C015_ritsuko.jpg/260px-OP_C015_ritsuko.jpg" alt="Uma Imagem" />
            </div>
         </div>
         <div className="col-md-2 col-4 mt-2 text-center">
            <div class="card test" >
               <img class="card-img-top" src="https://wiki.evageeks.org/images/thumb/9/9b/08_C071_kaji.jpg/260px-08_C071_kaji.jpg" alt="Uma Imagem" />
            </div>
         </div>
      </div>
      */}
      <div className="row mt-4 mb-5">
         <div className="col-12">
            <h2 className="display-3 text-center">Watchmen</h2>
         </div>
         <div className="col-12 col-md-12 mt-2">
            <p className="artigo text-justify lead">
               Watchmen, já deixando claro, é meu filme de super heróis favorito, gosto de todos os seus aspectos, roteiro, trilha sonora, personagens, fotografia e toda áurea de Zack Snyder que eu sempre admirei.
            </p>
            <p className="artigo text-justify lead mt-1">
               O filme é uma visão das histórias em quadrinhos de Watchmen, nela não há poderes absurdos a não ser do Dr Manhattan, e mesmo ele, antes de virar um “Deus” era uma pessoa normal, o'que é uma das coisas mais importantes para mim na trama, super heróis com sentimentos humanos, problemas humanos, rotinas humanas e pecados humanos…
            </p>
            <div className="col-12 my-4 text-center">
               <img class="card-img-top" src="https://s.aficionados.com.br/imagens/rorschach.gif" alt="Uma Imagem" />
            </div>

            <div className="row">
                <p className="artigo text-justify lead col-md-6 col-12">
                Na verdade eu sempre odiei o cinema mainstream, simplesmente porque é exibido o que todos querem, o'que todos preferem e aceitam, não existe margem para desconforto ou reflexão. Tudo bem, eu entendo que desde quando somos humanos é assim que é, mas eu prefiro não ser, e escolho não ser, todos os dias. Claro que é agradável estar envolvido no hype, vibrar e se emocionar em multidões, mas quando se faz apenas isso, não se gera sentimentos particulares e independentes, para mim, são estes os mais importantes na vida de todos nós…
                </p>
                <div className="col-12 col-md-6 mt-2 text-center">
                    <img class="card-img-top" src="https://media1.tenor.com/images/efd86e0dcaa219b1438949eee11582b8/tenor.gif?itemid=11712200" alt="Uma Imagem" />
                </div>
            </div>

            <div className="col-12 col-md-12 d-flex align-items-center">
                        <blockquote class="blockquote my-5">
                            <p class="mb-0 text-center">"O mundo desgovernado não é moldado por forças metafísicas. Não é Deus que mata as crianças, não é o acaso que as trucida, nem é o destino que as dá de comer aos cães. Somos nós. Só nós."</p>
                            <footer class="blockquote-footer text-center">Rorschach<cite className="ml-2" title="Título da fonte">Watchmen</cite></footer>
                        </blockquote>
            </div>

            <div className="col12 col-md-12 mt-2">
                    <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="1903" height="778" src="https://www.youtube.com/embed/hVEspFZCmmA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                    </div>
            </div>

            <div className="row mt-5 ">
                <p className="artigo text-justify lead col-12">
                    Então vou partir daqui, sentimentos. Os super heróis de Watchmen, na verdade são pessoas normais, repletos de traumas, fraquezas e desejos. A construção da personalidade de cada um, reflete algum tipo de conceito e ideia sobre o mundo. Temos senso de justiça deturpados de todas as formas, cada um acredita numa forma de agir, numa forma de reconhecer um ao outro, numa forma de viver.
                </p>
            </div>
            
            <div className="row mt-2">
                <p className="artigo text-justify lead col-12">
                    
Eu me esforço para não dar spoilers durante minhas críticas, na verdade eu nem gostaria de chamá-las de críticas, pois tudo que escrevo é como me sinto sobre aquilo. O filme pode ter um magnífico roteiro, efeitos especiais, personagens e atores de peso, se não me tocar, eu não gosto, não é produtivo pra mim, cinema é uma forma de arte, posso dizer que uma das maiores dessa geração, se não a maior, assim, não faz sentido pra mim, assistir um filme que não me assimile reflexão, contestação, existência, paixão, repulsa ou desespero.

                </p>
            </div>

            <div className="row mt-2">
                <p className="artigo text-justify lead col-12">
                Com essa indagação, fundamento minha opinião de Watchmen ser o melhor filme de super heróis, no quesito existencialismo. Ele não possui um roteiro muito bem desenvolvido, muitas pontas permanecem abertas, mas o decorrer da filosofia de cada personagem, sua maneira de julgar as ações da humanidade, agir com base em seu personagem, criado para fazer uma metáfora a realidade, faz você esquecer que é um filme de super heróis, e compreender como uma realidade alternativa, política, experimental, real e fascinante.


                </p>
            </div>

            <div className="col12 col-md-12 mt-2">
                    <div class="embed-responsive embed-responsive-16by9">

                    <iframe width="1903" height="778" src="https://www.youtube.com/embed/Bop8kb2dgNs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>
            </div>

            <div className="row mt-5">
                <p className="artigo text-center lead col-12">

                Nesta opinião, procurei não dar spoilers sobre a trama, porque quero que você assista como algo novo, como algo diferente, como algo profundo. Não inicie com expectativas superficiais de filmes marvel, não pense em efeitos especiais, pense em você, em seu senso de justiça, eu seu senso de desejo, em sua existência, e fará um bom proveito.

                </p>
            </div>

            <hr/>

            <div className="row text-center">
                <div className="col-md-6 col-12 mt-5">
                <h4 className="">Trilha da Série</h4>
                <iframe src="https://open.spotify.com/embed/album/5643V0ojEFh2bULWiPhuhs" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>

                <div className="col-md-6 col-12 mt-5">
                <h4 className="">Minha trilha favorita da Série</h4>
                <iframe src="https://open.spotify.com/embed/track/1QGQ7wB1KZfpbbWNFCaVGm" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
            </div>


            
         </div>
      </div>
   </div>
</Content>