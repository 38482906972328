import React from 'react'
import './Footer.css'


export default props =>
    <footer className="footer d-flex align-items-center">
        <div className="container">
            <div class="mt-1">
                <div className="align-footer">
                    <p class="lucas-direitos no-margin">© Criado e hospedado por<span class="h6 ml-2 mb-0"><a className="lucas" href="https://twitter.com/Agnoos_">Lucas Ricardo</a></span></p>
                </div>
            </div>
        </div>
    </footer>
