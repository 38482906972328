import React from 'react'
import Content from '../Content'
import './topicos.css'

import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default props =>
    <Content>
        <div class="jumbotron jumbotron-fluid bg-politic">
            <div class="container">
                <h1 class="display-4"></h1>
                <p class="lead"></p>
            </div>
        </div>

        {/* Section */}



        {/* <p className="menor text-justify">Acho que escrever é uma forma de organizar minhas coisas, me autocriticar. Eu mudo muito de tempos em tempos, opiniões e preferências, às vezes olho para as lembranças que os aplicativos de fotos e o facebook me mandam, tem coisas que eu dou risada por vergonha alheia, tem outras que explicam muito do que penso hoje, sabe quando você pensa, como isso foi acontecer… quando você tem determinados registros sobre as decisões que tomou, as coisas fazem mais sentido. Eventualmente posso usar algo aqui como referência para alguma coisa, mas a princípio, tudo aqui escrito é para uso pessoal meu mesmo, para servir de caderno de notas, aleatório, fuck norma culta hehe</p> */}


        {/* Fim da Section */}

        {/* Section */}

        <div class="container mt-5">

            <div className="row">
                <div className="col-6">
                    <h5 className="text-left menor">HMM</h5>
                </div>
                <div className="col-6">
                    <h5 className="text-right menor">2021</h5>
                </div>
            </div>

            <hr />


            <div className="row text-center mt-2">
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/235baf8b43017e2e48ff4c0b96816b4b/4d0b2855990c55bd-8c/s540x810/710355e84b905e29c3b6a2d89b6b5bfaa4014607.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">Retrospectiva Spotify</h5>
                            <p class="card-text menor">Indie Rock, Underground, Punk, Crystal Castles..</p>
                            <a href="/wrapped" class="btn btn-danger" target='_blank'>make sense</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/28a0e3f8d60dd6903e538e423cf2e6f7/aa5232294d45d93e-d0/s400x600/fb8965e9610a23f9f4cedf894ad14d79ebf8bb5c.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">American Psycho</h5>
                            <p class="card-text menor">Uma pena 4chan topics virarem tendencia de tiktok, ninguem mais é original, é tudo igual... mas faz parte...</p>
                            <a href="https://www.youtube.com/embed/-Gk6h7Ps2lI" class="btn btn-primary" target='_blank'>confession</a>
                        </div>
                    </div>

                </div>
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/9f1521e1f7c796147e34b896d9228745/014cc8d3c87f60fd-0a/s400x600/5dd018fb5ce430e7a014c93672da0a3067cdc084.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">Fight Club</h5>
                            <p class="card-text menor">Friedrich Nietzsche, emasculação, Ubersmach, SLAVE MORALITY</p>
                            <a href="https://youtu.be/HN-1T9TsJ2k" class="btn btn-secondary" target='_blank'>...</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/3f34db0e0f99775a4e409eecdf673bd7/3bdfb517cffdeb54-a6/s640x960/a49ab619246a24955316d93c9939f6b22b891731.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">My Russian Playlist</h5>
                            <p class="card-text menor">Да здравствует русская мать, вот андеграундное искусство в агонии</p>
                            <a href="https://open.spotify.com/playlist/7wi06IZjazOJ5l9VcP4iAo?si=f218f99746b14910" class="btn btn-warning" target='_blank'>инцел</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-12">
                    <img class="card-img-top" src="https://64.media.tumblr.com/f8f539df4e31dabf46912846e8c0f239/22d107903fd1a7a8-27/s400x600/0ae2462e7ae614f776320ae75c1aaa5c589b1fdb.gifv" alt="Card image cap"></img>
                </div>

                <div className="col-md-4 col-12">
                    <iframe width="350" height="230" src="https://www.youtube.com/embed/WWyVNXgg94U?start=95" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <div className="col-md-4 col-12">
                    <img class="card-img-top" src="https://64.media.tumblr.com/10898c692c90a3b1205689e5bbb66348/22d107903fd1a7a8-c1/s400x600/eba4944f9a51211d28ffcabffe234dfe386a4f61.gifv" alt="Card image cap"></img>
                </div>
            </div>


            <div className="row mb-5">
                <div className="col-md-4 col-12">
                    <img class="card-img-top" src="https://64.media.tumblr.com/e29b03b8c33aa734b34b3a839014b052/22d107903fd1a7a8-97/s400x600/bbce99e90d191bfd820082246ef9fa959e9fc5f4.gifv" alt="Card image cap"></img>
                </div>

                <div className="col-md-4 col-12">
                    <iframe width="350" height="230" src="https://www.youtube.com/embed/3vjkh-acmTE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <div className="col-md-4 col-12">
                    <img class="card-img-top" src="https://64.media.tumblr.com/d3da54bb31cf2ba9255f22e64b31f57e/22d107903fd1a7a8-67/s400x600/5db6cac4bd6157209bf7c631cd24aae836fd044f.gifv" alt="Card image cap"></img>
                </div>
            </div>


            <div className="row text-center mt-2">
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/68f7bad08ee82e49872f051fda1a2426/c20e5f1b4a7b2f41-8c/s400x600/5daf54d13b539312574ccbf1318da1d2f4505b01.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">The Nightcrawler</h5>
                            <p class="card-text menor">AHHHHHHHHHHHHHHHHHHHHHH QUE MARAVILHAAAAAA</p>
                            <a href="https://www.imdb.com/title/tt2872718/" class="btn btn-danger" target='_blank'>Cidade Alerta</a>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/45d7a71d943b0adcccf4ce28644d5996/0d790098ad4bcb0e-61/s400x600/ae2a79ba5cf0d719d39998c1559b26b6d62244a4.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">The Comedian</h5>
                            <p class="card-text menor">“... So in order to save this planet, I have to trick it... with the greatest practical joke in human history.”</p>
                            <a href="https://youtu.be/Ye186dk3v8Y" class="btn btn-secondary" target='_blank'>Ozymandias</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/d7fe4dcaa8b7270baf4b7ea9edc89ab5/90bfefbd458e509e-76/s400x600/cd356baad03c349d56b83a5d7ae0cecbc9567ad2.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">CHAOS</h5>
                            <p class="card-text menor">“...some men aren't looking for anything logical, like money. They can't be bought, bullied, reasoned, or negotiated with.”</p>
                            <a href="https://youtu.be/Z4hknCDVBfU" class="btn btn-primary" target='_blank'>HL 2009 F</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/9b9788abe86044d627ac8de1dd1aa7c3/02dfef2736b73b14-a3/s400x600/f5beeeeba9677acfdc7e88a0a111ff88b272cdce.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">Ramona Flowers</h5>
                            <p class="card-text menor">NegativeXp - World Ruined a Whole Generation of Women</p>
                            <a href="https://youtu.be/e8rBR6yoF70" class="btn btn-success" target='_blank'>Sorry!</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row text-center mt-2">
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/2dca4b27a127de72b3dad556a402eddd/b1ef940bb1fc161c-e1/s400x600/1dc2a2665de9bc9de70c4401e777490ee6421ef5.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">ScreamoOOO</h5>
                            <p class="card-text menor">“Life's just a pissing contest
                                And nobody exists on purpose
                                If you have no idea where you're going
                                It's fine, neither does anyone”</p>
                            <a href="https://youtu.be/xOlsM1cIoSA" class="btn btn-warning" target='_blank'>rape</a>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/190f8d26270f6298a9acfa6c2693c364/3af2a6db893eebfc-ed/s400x600/282255ca544f1c2cd3359eae55be3bf5f263e2df.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">help the boys</h5>
                            <p class="card-text menor">“All the other kids with the pumped up kicks
                                You better run, better run outrun my gun
                                All the other kids with the pumped up kicks
                                You better run, better run faster than my bullet”</p>
                            <a href="https://youtu.be/VBtNPtG1QK0" class="btn btn-primary" target='_blank'>PROUNI</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/9c276ace5dee1f2b44fba8a08f815ff5/7178d3ee82246b32-fc/s400x600/28ceb97d7e0a2381ddb40a191e7ec5c588089932.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">Androgenic</h5>
                            <p class="card-text menor">“...Will you ever preserve? Will you ever exhume?
                                Will you watch petals shed from flowers in bloom?.”</p>
                            <a href="https://youtu.be/DfxQj4jKJ0E" class="btn btn-info" target='_blank'>Futanari</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6 text-center">
                    <div class="card mb-2">
                        <img class="card-img-top" src="https://64.media.tumblr.com/cce9c030f05a3d4f3a2ee072c376241e/d2661700e46f2813-23/s500x750/0f1c7de35994295ac73b5f2cad11539173eb146b.gifv" alt="Card image cap"></img>
                        <div class="card-body">
                            <h5 class="card-title">Where Did You Sleep Last Night</h5>
                            <p class="card-text menor">NMAAAAAAAAAAAAAAOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO...</p>
                            <a href="https://youtu.be/hEMm7gxBYSc" class="btn btn-secondary" target='_blank'>Pines</a>
                        </div>
                    </div>
                </div>

                <hr className=' mb-5' />

                <h3 className="">best trilogy</h3>
                <hr />

                <div className="">
                    <div className="row text-center mt-2">
                        <div className="col-md-4 col-4 text-center">
                            <h5 className="my-2">Before Sunrise</h5>
                            <div class="card test" >
                                <img class="card-img-top" src="https://a.ltrbxd.com/resized/film-poster/5/1/9/7/4/51974-before-sunrise-0-500-0-750-crop.jpg?v=de3c4718dc" alt="" />
                                <Box className="text-center my-2" component="fieldset" mb={5} borderColor="transparent">
                                    <Rating name="read-only" value={5} readOnly precision={0.1} size="medium" />
                                </Box>
                            </div>
                        </div>
                        <div className="col-md-4 col-4 text-center">
                            <h5 className="my-2">Before Sunset</h5>
                            <div class="card test" >
                                <img class="card-img-top" src="https://a.ltrbxd.com/resized/film-poster/5/1/9/7/0/51970-before-sunset-0-500-0-750-crop.jpg?v=059bc2bbc0" alt="" />
                                <Box className="text-center my-2" component="fieldset" mb={5} borderColor="transparent">
                                    <Rating name="read-only" value={5} readOnly precision={0.1} size="medium" />
                                </Box>
                            </div>
                        </div>
                        <div className="col-md-4 col-4 text-center">
                            <h5 className="my-2">Before Midnight</h5>
                            <div class="card test" >
                                <img class="card-img-top" src="https://a.ltrbxd.com/resized/film-poster/1/0/2/4/4/4/102444-before-midnight-0-500-0-750-crop.jpg?v=ce6aba20f1" alt="" />
                                <Box className="text-center my-2" component="fieldset" mb={5} borderColor="transparent">
                                    <Rating name="read-only" value={5} readOnly precision={0.1} size="medium" />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr className='mt-5' />


        {/* Fim da Section */}

    </Content>
